import { gql } from "@apollo/client";
import {
  WORKER_FRAGMENT,
  WORKER_CERTIFICATION_FRAGMENT,
  WORKED_TIMESHEET_FRAGMENT,
  IMAGE_FILE_FRAGMENT,
  SALESFORCE_INTEGRATION_FRAGMENT,
  WORKER_CREDENTIAL_FRAGMENT,
} from "../apollo.fragment";

export const VERIFY_WORKER_QUERY = gql`
  query verifyWorker($token: String!) {
    workerToken(token: $token) {
      token
      worker {
        ...workerFragment
        recruiter {
          id
          name
          phone
          email
          title
        }
        company {
          salesforceIntegration {
            ...salesforceIntegrationFragment
          }
        }
      }
    }
  }

  ${WORKER_FRAGMENT}
  ${SALESFORCE_INTEGRATION_FRAGMENT}
`;

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
  query getWorkerDetailTimeQuery($id: String!) {
    worker(id: $id) {
      ...workerFragment
      company {
        salesforceIntegration {
          ...salesforceIntegrationFragment
        }
      }
      workedTimesheets {
        ...workedTimesheetFragment
        timesheetImages {
          imageUrls(size: ["100x100", "200x200"])
          ...imageFileFragment
        }
      }
    }
  }

  ${WORKER_FRAGMENT}
  ${SALESFORCE_INTEGRATION_FRAGMENT}
  ${WORKED_TIMESHEET_FRAGMENT}
  ${IMAGE_FILE_FRAGMENT}
`;

export const GET_CREDENTIALING_URL_QUERY = gql`
  query getWorkerDetailCredentialingQuery($id: String!) {
    worker(id: $id) {
      id
      credentialingUrl
    }
  }
`;

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
  query getWorkerCertificationsQuery($id: String!) {
    workerCertifications(workerId: $id) {
      ...workerCertificationFragment
      licenseImages {
        ...imageFileFragment
      }
    }
  }

  ${WORKER_CERTIFICATION_FRAGMENT}
  ${IMAGE_FILE_FRAGMENT}
`;

export const GET_WORKER_CREDENTIALS_QUERY = gql`
  query getWorkerCredentialsQuery(
    $filter: WorkerCredentialStatusEnum
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    workerCredentials(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      nodes {
        ...workerCredentialFragment
      }
    }
  }

  ${WORKER_CREDENTIAL_FRAGMENT}
`;
export const GET_WORKER_SOCIAL_QUERY = gql`
  query getWorkerSocialQuery {
    workerSocialAuthentications {
      providerName
      id
      providerUuid
      email
    }
  }
`;

export const GET_WORKERS_QUERY = gql`
  query getWorkers(
    $keywordSearch: String
    $filter: WorkerFilters
    $sortBy: WorkerSort
    $limit: Int
    $offset: Int
  ) {
    workers(
      keywordSearch: $keywordSearch
      filter: $filter
      sortBy: $sortBy
      limit: $limit
      offset: $offset
    ) {
      ...workerFragment
    }
  }
  ${WORKER_FRAGMENT}
`;

export const GET_IMPORT_WORKER_RESULT_QUERY = gql`
  query importWorkerFileQuery($id: String!) {
    importWorkerFile(id: $id) {
      status
      result {
        added
        count
        errors
        updated
      }
    }
  }
`;
