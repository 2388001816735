import moment from "moment";
import { coerceNumberType } from "../type/number";

export const parseTimeString = (s) => {
  const pattern = /([\d]+):([\d]+):?[\d]*[ ]*([\w]*)/;
  const matches = s.match(pattern);
  if (!matches) {
    return null;
  }
  const [, hours, minutes, ampm] = matches;
  let h = coerceNumberType(hours);
  const m = coerceNumberType(minutes);
  h = ampm.toLowerCase() === "pm" ? h + 12 : h;
  return [h, m];
};

export const normalizeTime24 = (hours, minutes) => {
  let h = hours;
  let m = minutes;
  if (minutes > 59) {
    m = minutes % 60;
    h += Math.floor(minutes / 60);
  }
  if (h > 23) {
    h %= 24;
  }
  return [h, m];
};

export const convert24to12 = (hours, minutes) => {
  const d = new Date(1975, 1, 1, hours, minutes, 0);
  const timeString = d.toLocaleTimeString(undefined, { hourCycle: "h12" });
  const [h, m] = parseTimeString(timeString);
  return [h, m];
};

export const toMomentDate = (value) => {
  const updateValue = value?.replace("UTC", "+0000");
  return moment(updateValue, "YYYY-MM-DD HH:mm:ss ZZ");
};

export const toDateView = (value, format = "llll") => {
  return toMomentDate(value).format(format);
};
