import React from "react";
import {
  SvgIcon,
  Typography as MuiTypography,
  useTheme,
} from "@material-ui/core";
import { typographyStyle } from "./typography.style";

export const Typography = ({
  icon: Icon,
  children,
  variant,
  style,
  ...props
}) => {
  const themes = useTheme();
  const variantStyles = themes.typography[variant];

  return (
    <MuiTypography
      {...props}
      variant={variant}
      style={{ ...variantStyles, ...style }}
      css={typographyStyle}
    >
      {Icon && (
        <SvgIcon>
          <Icon />
        </SvgIcon>
      )}
      {children}
    </MuiTypography>
  );
};
