import { gql } from "@apollo/client";
import {
  COMPANY_FRAGMENT,
  EMPLOYER_FRAGMENT,
  RECRUITER_FRAGMENT,
} from "~/common/apollo/apollo.fragment";

export const VERIFY_RECRUITER_QUERY = gql`
  query verifyRecruiter($token: String!) {
    recruiterToken(token: $token) {
      token
      recruiter {
        ...recruiterFragment
      }
    }
  }

  ${RECRUITER_FRAGMENT}
`;

export const GET_EMPLOYER_INIT = gql`
  query {
    employerAppInit {
      totalUnreadNotification
      unreadMessageCounter
      employer {
        id
        title
        firstName
        company {
          id
        }
        employerFacilities {
          facility {
            id
            name
            description
            workplaceImageUrl
          }
        }
      }
      dashboard {
        totalClients
        totalPositionsOpen
        totalWorkersPerClient
        totalWorkersWorking
        shiftRequestOnboardings
      }
    }
  }
`;

export const GET_EMPLOYER_SUMMARY = gql`
  query {
    employerSummary {
      dateRanges
      shifts {
        fillRate
        newShiftsCount
        recentShifts {
          id
          shiftRequest {
            name
          }
          facility {
            id
            name
          }
        }
      }
      jobs {
        activeJobsCount
        newJobsCount
        highlightJobsCount {
          active
          closed
          completed
        }
      }
      workers {
        activeWorkersCount
        newWorkersCount
        recentNewWorkers {
          id
          firstName
          lastName
          badges {
            iconUrl
          }
        }
      }
      reports(filter: { timePeriod: weekly }) {
        shiftsCompletedCount
        jobsCompletedCount
      }
      shiftRequestOnboardings
    }
  }
`;

export const GET_EMPLOYER_QUERY = gql`
  query getEmployer($id: String!) {
    employer(id: $id) {
      ...employerFragment
    }
  }
  ${EMPLOYER_FRAGMENT}
`;

export const GET_EMPLOYERS_QUERY = gql`
  query getEmployers(
    $keywordSearch: String
    $filter: EmployerFilter
    $sortBy: EmployerSort
    $limit: Int
    $offset: Int
  ) {
    employers(
      keywordSearch: $keywordSearch
      filter: $filter
      sortBy: $sortBy
      limit: $limit
      offset: $offset
    ) {
      agreedToTerms
      avatarUrl
      company {
        ...companyFragment
      }
      employerFacilities {
        facility {
          id
          name
        }
      }
      email
      firstName
      id
      lastName
      lastSignInAt
      phone
      title
    }
  }
  ${COMPANY_FRAGMENT}
`;
