import { eventClient } from "@opus/web.core.lib.event-tracking";
import axios from "axios";
import { EVENTS, ONE_DAY } from "~/common/constants";
import { USER_TITLE } from "~/common/constants/users.constant";
import {
  store,
  computed,
  action,
  observable,
  persist,
  event,
} from "~/common/mobx.decorator";
import { appStore } from "./app.store";
import { isEmpty, some } from "lodash";
import { apolloClient, SIGNOUT_RECRUITER_MUTATION } from "~/common/apollo";

class ProfileItem {
  @persist @observable id;
  @persist @observable firstName;
  @persist @observable lastName;
  @persist @observable title;
}

@store({ persist: true })
class AuthStore {
  @persist @observable token = "";
  @persist @observable expiresAt = 0;
  @persist("object", ProfileItem) @observable profile;
  @observable showChangePasswordDialog = false;

  @action
  changeActiveTabIndex = (tabIndex) => {
    if (this.activeTabIndex === tabIndex) {
      return;
    }

    this.prevActiveTabIndex = -1;
    this.activeTabIndex = tabIndex;
  };

  @computed
  get recruiter() {
    return this.profile?.recruiter;
  }

  @computed
  get authorization() {
    return !this.token || Date.now() > this.expiresAt ? "" : this.token;
  }

  @computed
  get features() {
    return [
      "recruiter-home",
      "recruiter-company",
      "recruiter-account",
      "recruiter-badges",
      "recruiter-messages",
      "recruiter-job-orders",
      "recruiter-assignments",
      "recruiter-create-job-order",
      "recruiter-shifts",
      "recruiter-clients",
      "recruiter-locations",
      "recruiter-workers",
      "recruiter-onboarding",
      "recruiter-teams",
      "recruiter-report",
      "recruiter-report-analytics",
      "recruiter-help-and-feedback",
      "recruiter-contact-internal-team",
      "common-change-password",
      "recruiter-message-threads",
      "recruiter-employers",
      "recruiter-import-worker-result",
    ];
  }

  @computed
  get id() {
    return this.authorization && this.profile?.id;
  }

  @computed
  get firstName() {
    return this.profile?.firstName;
  }

  @computed
  get lastName() {
    return this.profile?.lastName;
  }

  @computed
  get fullName() {
    return [this.firstName, this.lastName].join(" ").trim();
  }

  @computed
  get avatarUrl() {
    return this.profile?.avatarUrl;
  }

  @computed
  get address() {
    const hasValue = some(this.employerAddress, (value) => !isEmpty(value));
    if (!hasValue) {
      return "";
    }
    return [
      this.employerAddress?.address1,
      this.employerAddress?.address2,
      this.employerAddress?.city,
      this.employerAddress?.state,
      this.employerAddress?.zipcode,
    ]
      .join(", ")
      .trim();
  }

  @computed
  get title() {
    return this.profile?.title;
  }

  @computed
  get phone() {
    return this.profile?.phone;
  }

  @computed
  get email() {
    return this.profile?.email;
  }

  @computed
  get activated() {
    return (
      this.isAdminLoggedIn ||
      this.isManagerLoggedIn ||
      this.isOnsiteManagerLoggedIn
    );
  }

  @computed
  get isAdminLoggedIn() {
    return this.profile?.title === USER_TITLE.admin;
  }

  @computed
  get isManagerLoggedIn() {
    return this.profile?.title === USER_TITLE.manager;
  }

  @computed
  get isOnsiteManagerLoggedIn() {
    return this.profile?.title === USER_TITLE.onsite_manager;
  }

  @action
  changeProfile = async (profile) => {
    this.profile = profile;
    if (profile) {
      eventClient.setUserId(profile.id);
      eventClient.setUserProperties({
        id: profile.id,
        email: profile.email,
        first_name: profile?.firstName,
        last_name: profile?.lastName,
        company_id: appStore.id,
        title: profile?.title,
      });

      // ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
      // await matchAnonymousEvents();
    }

    // notifyStore.signal.push(() => {
    // 	notifyStore.signal.sendTags(profile)
    // 	notifyStore.signal.setExternalUserId(profile?.id)
    //})

    // Update LiveChat Info
    // if (window.LC_API && typeof window.LC_API.set_visitor_name === "function") {
    //   window.LC_API.set_visitor_name(
    //     [profile?.firstName, profile?.lastName].join(" ").trim()
    //   );
    //   window.LC_API.set_visitor_email(profile?.email);
    // }
  };

  @action
  changeToken = async (token = "", duration = ONE_DAY * 30) => {
    this.token = token;
    this.expiresAt = token ? Date.now() + duration : -1;

    if (token) {
      axios.defaults.headers.common["Auth-Token"] = token;
    } else {
      delete axios.defaults.headers.common["Auth-Token"];
    }
  };

  @action
  setShowChangePasswordDialog = (value) => {
    this.showChangePasswordDialog = value;
  };

  @event(EVENTS.authStore.logout)
  async logout() {
    if (isEmpty(this.id)) {
      return;
    }
    const bkToken = this.token;
    this.changeToken("");
    this.profile = {};
    eventClient.setUserId(null);
    eventClient.setUserProperties(null);

    await apolloClient.mutate({
      mutation: SIGNOUT_RECRUITER_MUTATION,
      context: {
        headers: {
          "Auth-Token": bkToken,
        },
      },
    });
  }
}

export const authStore = new AuthStore();
