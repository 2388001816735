import { gql } from "@apollo/client";
import { JOB_REPORT_FRAGMENT } from "../apollo.fragment";

export const UPDATE_JOB_REPORT_MUTATION = gql`
  mutation updateJobReportMutation(
    $id: String!
    $state: JobReportStateEnum
    $employerNote: String
    $adjustedWorkedHours: Float
    $adjustedPayment: Float
  ) {
    updateJobReport(
      id: $id
      state: $state
      employerNote: $employerNote
      adjustedWorkedHours: $adjustedWorkedHours
      adjustedPayment: $adjustedPayment
    ) {
      ...jobReportFragment
    }
  }
  ${JOB_REPORT_FRAGMENT}
`;
