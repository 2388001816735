import React from "react";
export const FEATURES = {
  // HEALTHGIG FEATURES
  "recruiter-home": React.lazy(() => import("~/features/recruiter-home")),
  "recruiter-company": React.lazy(() => import("~/features/recruiter-company")),
  "recruiter-badges": React.lazy(() => import("~/features/recruiter-badges")),
  "recruiter-messages": React.lazy(() =>
    import("~/features/recruiter-messages")
  ),
  "recruiter-job-orders": React.lazy(() =>
    import("~/features/recruiter-job-orders")
  ),
  "recruiter-assignments": React.lazy(() =>
    import("~/features/recruiter-assignments")
  ),
  "recruiter-clients": React.lazy(() => import("~/features/recruiter-clients")),
  "recruiter-locations": React.lazy(() =>
    import("~/features/recruiter-locations")
  ),
  "recruiter-workers": React.lazy(() => import("~/features/recruiter-workers")),
  "recruiter-teams": React.lazy(() => import("~/features/recruiter-teams")),
  "recruiter-report": React.lazy(() => import("~/features/recruiter-report")),
  "recruiter-report-analytics": React.lazy(() =>
    import("~/features/recruiter-report-analytics")
  ),
  "recruiter-create-job-order": React.lazy(() =>
    import("~/features/recruiter-create-job-order")
  ),
  "recruiter-help-and-feedback": React.lazy(() =>
    import("~/features/recruiter-help-and-feedback")
  ),
  "recruiter-contact-internal-team": React.lazy(() =>
    import("~/features/recruiter-contact-internal-team")
  ),
  "recruiter-account": React.lazy(() => import("~/features/recruiter-account")),
  "recruiter-employers": React.lazy(() =>
    import("~/features/recruiter-employers")
  ),
  "recruiter-onboarding": React.lazy(() =>
    import("~/features/recruiter-onboarding")
  ),
  "recruiter-import-worker-result": React.lazy(() =>
    import("~/features/recruiter-import-worker-result")
  ),

  // COMMON FEATURES
  "common-signin": React.lazy(() => import("~/features/common-signin")),
  "common-errors": React.lazy(() => import("~/features/common-errors")),
  "common-reset": React.lazy(() => import("~/features/common-reset")),
  "common-change-password": React.lazy(() =>
    import("~/features/common-change-password")
  ),
};
