import { unflatten } from "flat";
import { find, get, isEmpty, keyBy, mapValues } from "lodash";

export const toFormErrors = (error, baseField = "__ERROR") => {
  const errors = unflatten(
    mapValues(
      keyBy(error?.graphQLErrors, (value) =>
        get(value, "extensions.attribute", baseField)
      ),
      "message"
    )
  );

  if (isEmpty(errors)) {
    return { [baseField]: error?.message };
  }

  return errors;
};

export const isExistingEmailError = (error = {}) => {
  const emailExist = find(
    error?.graphQLErrors,
    ({ extensions }) =>
      extensions?.attribute === "email" && extensions?.error_type === "taken"
  );
  if (emailExist !== undefined) {
    return true;
  }
  return false;
};

export const isPasswordExpiredError = (error = {}) => {
  const emailExist = find(
    error?.graphQLErrors,
    ({ extensions }) =>
      extensions?.attribute === "password" &&
      extensions?.error_type ===
        "Your temporary password has expired. A new temporary password has now been sent to your email."
  );
  if (emailExist !== undefined) {
    return true;
  }
  return false;
};
