import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { authStore, notifyStore, routerStore } from "~/stores";
import { toJS } from "~/common/mobx.decorator";
import { EVENTS, PATHS } from "../constants";
import { eventBus } from "mobx-event-bus2";
import ApolloLinkTimeout from "apollo-link-timeout";
import { captureException, captureFatalException } from "../helpers";

const timeout = new ApolloLinkTimeout(60000);

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_PROXY_GRAPHQL_URL}${PATHS.common.graphql}`,
  fetchOptions: {},
});

const authLink = setContext((_, { headers = {} }) => {
  const token = toJS(authStore.authorization);
  const customHeaders = {};

  if (token) {
    customHeaders["Auth-Token"] = token;
  }

  customHeaders["GraphiQL_Authorization"] =
    process.env.REACT_APP_PROXY_GRAPHQL_AUTHENTICATION;

  return { headers: { ...customHeaders, ...headers } };
});

const errorLink = onError((error) => {
  const { graphQLErrors } = error;

  if (graphQLErrors?.[0]?.message === "Invalid Token") {
    eventBus.post(EVENTS.authStore.logout);
  }

  if (
    graphQLErrors?.[0]?.extensions?.code === 406 &&
    graphQLErrors?.[0]?.extensions?.type === "unauthenticated"
  ) {
    if (authStore.id) {
      notifyStore.error("Token Expired");
    }
    eventBus.post(EVENTS.authStore.logout);
  }

  if (graphQLErrors?.[0]?.extensions?.code === 403) {
    notifyStore.error(
      graphQLErrors?.[0]?.message || graphQLErrors?.[0]?.extensions?.title
    );
    routerStore.goPage(PATHS.recruiter.home);
  }

  if (graphQLErrors?.[0]?.extensions?.code === 428) {
    authStore.setShowChangePasswordDialog(true);
  }

  if (graphQLErrors?.[0]?.extensions?.code === 500) {
    captureFatalException("[API Response Error]", error);
  } else {
    captureException("Apollo Client", error);
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const apolloClient = new ApolloClient({
  link: timeout.concat(authLink.concat(errorLink.concat(httpLink))),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions,
});
