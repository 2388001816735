import { PATHS } from "./path.constant";
import {
  ChatSvg,
  ClientsSvg,
  CompanySvg,
  DownSvg,
  HomeSvg,
  Jobs2Svg,
  JobsSvg,
  ReportSvg,
} from "~/components/icons";

export const ADMIN_FEATURES = [
  {
    code: "recruiter-home",
    name: "HOME",
    path: PATHS.recruiter.home,
    icon: HomeSvg,
    auth: true,
    showAppBar: true,
    showSideBar: true,
    useLayout: true,
    eventId: "dashboard-nav_btn",
    subMenu: null,
  },
  {
    code: "recruiter-jobs",
    name: "JOB_MANAGEMENT",
    path: PATHS.recruiter.jobs,
    icon: JobsSvg,
    auth: true,
    showAppBar: true,
    showSideBar: true,
    useLayout: true,
    eventId: "jobs-nav_btn",
    subMenu: [
      {
        code: "recruiter-job-orders",
        name: "JOB_ORDERS",
        path: PATHS.recruiter.job_orders,
        icon: DownSvg,
        auth: true,
        showAppBar: true,
        showSideBar: true,
        useLayout: true,
        eventId: "job-orders-nav_btn",
      },
      {
        code: "recruiter-assignments",
        name: "SHIFTS",
        path: PATHS.recruiter.shifts,
        icon: DownSvg,
        auth: true,
        showAppBar: true,
        showSideBar: true,
        useLayout: true,
        eventId: "jobs-nav_btn",
      },
    ],
  },
  {
    code: "recruiter-workers",
    name: "WORKFORCE",
    path: PATHS.recruiter.workers,
    icon: Jobs2Svg,
    auth: true,
    showAppBar: false,
    showSideBar: true,
    useLayout: true,
    subMenu: [
      {
        code: "recruiter-workers",
        name: "ACTIVE_WORKERS",
        path: PATHS.recruiter.workers,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
        hideTitle: true,
      },
      {
        code: "recruiter-onboarding",
        name: "ONBOARDING",
        path: PATHS.recruiter.onboarding,
        icon: DownSvg,
        auth: false,
        showAppBar: true,
        showSideBar: true,
        useLayout: true,
        eventId: "badges-nav_btn",
      },
      {
        code: "recruiter-import-worker-result",
        name: "IMPORT_WORKER_RESULT",
        path: PATHS.recruiter.import_worker_result,
        icon: Jobs2Svg,
        auth: true,
        showAppBar: true,
        showSideBar: false,
        useLayout: true,
        eventId: "job-orders-nav_btn",
        activeSubParentCode: "recruiter-workers",
      },
    ],
  },
  {
    code: "recruiter-report",
    name: "REPORTING",
    path: PATHS.recruiter.report_dashboard,
    icon: ReportSvg,
    auth: true,
    showAppBar: false,
    showSideBar: true,
    useLayout: true,
    subMenu: [
      {
        code: "recruiter-report-analytics",
        name: "DASHBOARD",
        path: PATHS.recruiter.report_dashboard,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
      },
      {
        code: "recruiter-report",
        name: "SHIFTS_REPORT",
        path: PATHS.recruiter.report,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
      },
    ],
  },
  {
    code: "recruiter-clients",
    name: "CLIENT_MANAGEMENT",
    path: PATHS.recruiter.clients,
    icon: ClientsSvg,
    auth: true,
    showAppBar: false,
    showSideBar: true,
    useLayout: true,
    subMenu: [
      {
        code: "recruiter-clients",
        name: "CLIENTS",
        path: PATHS.recruiter.clients,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
      },
      {
        code: "recruiter-locations",
        name: "FACILITY_LOCATIONS",
        path: PATHS.recruiter.locations,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
      },
      {
        code: "recruiter-employers",
        name: "CONTACTS",
        path: PATHS.recruiter.contacts,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
      },
    ],
  },
  {
    code: "recruiter-messages",
    name: "MESSAGES",
    path: PATHS.recruiter.messages,
    icon: ChatSvg,
    auth: true,
    showAppBar: true,
    showSideBar: true,
    useLayout: true,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-message-threads",
    name: "MESSAGES",
    path: PATHS.recruiter.message_threads,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: false,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-company",
    name: "COMPANY",
    path: PATHS.recruiter.company,
    icon: CompanySvg,
    auth: true,
    showAppBar: true,
    showSideBar: false,
    useLayout: true,
    eventId: "company-nav_btn",
  },
  {
    code: "recruiter-help-and-feedback",
    name: "TECHNICAL_SUPPORT",
    path: PATHS.recruiter.technical_support,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: true,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-contact-internal-team",
    name: "STAFFING_HELP",
    headerName: "STAFFING_HELP",
    path: PATHS.recruiter.staffing_help,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: true,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-account",
    name: "ADMIN_ACCOUNT",
    path: PATHS.recruiter.account,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: true,
    eventId: "message-nav_btn",
  },
].filter(Boolean);

export const CLIENT_FEATURES = [
  {
    code: "recruiter-home",
    name: "HOME",
    path: PATHS.recruiter.home,
    icon: HomeSvg,
    auth: true,
    showAppBar: true,
    showSideBar: true,
    useLayout: true,
    eventId: "dashboard-nav_btn",
    subMenu: null,
  },
  {
    code: "recruiter-jobs",
    name: "JOB_MANAGEMENT",
    path: PATHS.recruiter.jobs,
    icon: JobsSvg,
    auth: true,
    showAppBar: true,
    showSideBar: true,
    useLayout: true,
    eventId: "jobs-nav_btn",
    subMenu: [
      {
        code: "recruiter-job-orders",
        name: "JOB_ORDERS",
        path: PATHS.recruiter.job_orders,
        icon: DownSvg,
        auth: true,
        showAppBar: true,
        showSideBar: true,
        useLayout: true,
        eventId: "job-orders-nav_btn",
      },
      {
        code: "recruiter-assignments",
        name: "SHIFTS",
        path: PATHS.recruiter.shifts,
        icon: DownSvg,
        auth: true,
        showAppBar: true,
        showSideBar: true,
        useLayout: true,
        eventId: "jobs-nav_btn",
      },
    ],
  },
  {
    code: "recruiter-report",
    name: "REPORTING",
    path: PATHS.recruiter.report_analytics,
    icon: ReportSvg,
    auth: true,
    showAppBar: false,
    showSideBar: true,
    useLayout: true,
    subMenu: [
      {
        code: "recruiter-report-analytics",
        name: "DASHBOARD",
        path: PATHS.recruiter.report_dashboard,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
      },
      {
        code: "recruiter-report",
        name: "SHIFTS_REPORT",
        path: PATHS.recruiter.report,
        icon: DownSvg,
        auth: true,
        showAppBar: false,
        showSideBar: true,
        useLayout: true,
      },
    ],
  },
  {
    code: "recruiter-messages",
    name: "MESSAGES",
    path: PATHS.recruiter.messages,
    icon: ChatSvg,
    auth: true,
    showAppBar: true,
    showSideBar: true,
    useLayout: true,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-message-threads",
    name: "MESSAGES",
    path: PATHS.recruiter.message_threads,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: false,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-help-and-feedback",
    name: "TECHNICAL_SUPPORT",
    path: PATHS.recruiter.technical_support,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: true,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-contact-internal-team",
    name: "STAFFING_HELP",
    headerName: "STAFFING_HELP",
    path: PATHS.recruiter.staffing_help,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: true,
    eventId: "message-nav_btn",
  },
  {
    code: "recruiter-account",
    name: "CLIENT_ACCOUNT",
    path: PATHS.recruiter.account,
    icon: ChatSvg,
    auth: true,
    showAppBar: false,
    showSideBar: false,
    useLayout: true,
    eventId: "message-nav_btn",
  },
].filter(Boolean);

export const COMMON_FEATURES = [
  {
    code: "common-signin",
    name: "SIGNIN",
    path: PATHS.common.signin,
    icon: "user",
    auth: false,
    showAppBar: false,
    showSideBar: false,
    useLayout: false,
  },
  {
    code: "common-reset",
    name: "RESET",
    path: PATHS.common.reset,
    icon: "user",
    auth: false,
    showAppBar: false,
    showSideBar: false,
    isDisabled: false,
    useLayout: false,
  },
  {
    code: "common-change-password",
    name: "REFER_TRAVELER",
    path: PATHS.common.change_password,
    icon: "user",
    auth: false,
    showAppBar: false,
    showSideBar: false,
    isDisabled: false,
    useLayout: false,
  },
  {
    code: "common-errors",
    name: "ERRORS",
    path: PATHS.common.errors,
    icon: "user",
    auth: false,
    showAppBar: false,
    showSideBar: false,
    isDisabled: false,
    useLayout: false,
  },
].filter(Boolean);
