import { gql } from '@apollo/client';

export const MARK_AS_READ_NOTIFICATION = gql`
    mutation MarkNotificationAsRead($id: String!) {
      markNotificationAsRead(id: $id) {
        success
      }
    }
`;

export const MARK_ALL_READ_NOTIFICATION = gql`
    mutation MarkAllNotificationsAsRead {
      markAllNotificationsStatus {
        success
      }
    }
`;