import spacetime from "spacetime";
import { toMomentDate } from "./conversion";
export const dateIsToday = (someDate) => {
  const today = spacetime.today();
  const date = spacetime(someDate);
  return today.isSame(date, "day");
};

/**
 * Datetime comparison
 *
 * @param      Date        1st Date (Ex: "2020-11-05 03:39:02 UTC")
 * @param      Date        2nd Date (Ex: "2020-11-05 03:40:02 UTC")
 *
 * @return     0           Two date is the same
 * @return     < 0         1st date is before 2nd date
 * @return     > 0         1st date is after 2nd date
 */
export const datetimeComparison = (date1, date2) => {
  const [value1, value2] = [toMomentDate(date1), toMomentDate(date2)];

  return value1.valueOf() - value2.valueOf();
};
