import { css } from "styled-components";

export const notifyDialogStyle = css`
  .MuiDialog-paper {
    > .MuiBox-root {
      min-width: 240px;

      .header {
        .MuiTypography-root {
          background-color: ${({ theme }) => theme.palette.common.white};
          color: ${({ theme }) => theme.palette.primary.main};
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.1px;
          text-transform: uppercase;
        }
      }
    }
  }

  .content-notify {
  }
  .content-notify p {
    font-size: 13px;
    text-align: left;
    font-family: "P22 Mackinac Pro";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #3c3c3c;
    &.MuiFormHelperText-root.Mui-error {
      color: #fe7d74;
    }
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 14px;
    }
  }
`;
