import { filter, map } from "lodash";
import moment from "moment";

export const updateJobReportAcitivities = (jobReportActivities) => {
  const newArr = map(jobReportActivities, (currentItem, index) => {
    const nextItem = jobReportActivities[index + 1];
    const startTime = currentItem.startTime;
    const endTime = nextItem ? nextItem.startTime : null;
    let error = false;

    if (!nextItem) {
      return { ...currentItem, durationInHour: 0, endTime, error };
    }

    if (nextItem && moment(startTime).isAfter(endTime)) {
      error = true;
    }

    const durationInMs = moment(endTime).diff(startTime);
    const durationInHours = moment.duration(durationInMs).asHours();

    return { ...currentItem, durationInHour: durationInHours, endTime, error };
  });

  return newArr;
};

export const isErrorAcitvities = (jobReportActivities) => {
  return filter(jobReportActivities, (item) => item?.error).length > 0;
};
