export const PATHS = {
  // COMMON
  common: {
    home: "/",
    reset: "/reset",
    signin: "/signin",
    errors: "/errors",
    change_password: "/change_password",
    api: "/api",
    graphql: "/graphql",
    signup: "/signup",
    tracking: "/tracking",
  },
  // Recruiter Portal
  recruiter: {
    home: "/home",
    company: "/company",
    badges: "/badges",
    shifts: "/shifts",
    messages: "/message_conversations",
    job_orders: "/job_orders",
    clients: "/clients",
    locations: "/locations",
    workers: "/workers",
    teams: "/teams",
    report_dashboard: "/report_dashboard",
    report: "/shifts_report",
    create_job_order: "/new_job_order",
    technical_support: "/technical_support",
    staffing_help: "/staffing_help",
    account: "/account",
    message_threads: "/message_threads/:id",
    contacts: "/contacts",
    onboarding: "/onboarding",
    import_worker_result: "/import_worker_result/:id",
  },
};
