import gql from "graphql-tag";
import {
  MESSAGE_CONVERSATION_FRAGMENT,
  MESSAGE_FRAGMENT,
} from "../apollo.fragment";

export const GET_MESSAGE_CONVERSATIONS_QUERY = gql`
  query getMessageConversations(
    $keyword: String
    $filter: MessageConversationFilter
    $limit: Int
    $offset: Int
  ) {
    messageConversations(
      keyword: $keyword
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      ...messageConversationFragment
    }
  }
  ${MESSAGE_CONVERSATION_FRAGMENT}
`;

export const GET_MESSAGES_QUERY = gql`
  query getMessages(
    $keyword: String
    $filter: MessageFilter!
    $limit: Int
    $offset: Int
  ) {
    messages(
      keyword: $keyword
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      ...messageFragment
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export const GET_MESSAGE_CONVERSATION_QUERY = gql`
  query getMessageConversationQuery($id: String!) {
    messageConversation(id: $id) {
      messageConversation {
        ...messageConversationFragment
      }
    }
  }
  ${MESSAGE_CONVERSATION_FRAGMENT}
`;
