import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore,
} from "mobx-react-router";
import { createBrowserHistory } from "history";
import { store, computed, action } from "~/common/mobx.decorator";
import { omit, some } from "lodash";
import { parse } from "query-string";
import { PATHS } from "~/common/constants";
import { UAParser } from "ua-parser-js";

const BASE_PATH = /^\/[^/]*/;
const INVALID_RETURN_PATHS = [
  PATHS.common.signin,
  PATHS.common.reset,
  PATHS.common.change_password,
  PATHS.common.signup,
  PATHS.common.tracking,
];

@store()
class RouterStore extends BaseRouterStore {
  @computed
  get uaString() {
    return window.navigator.userAgent;
  }

  @computed
  get uaParser() {
    return new UAParser(this.uaString);
  }

  @computed
  get os() {
    return this.uaParser.getOS();
  }

  @computed
  get device() {
    return this.uaParser.getDevice();
  }

  @computed
  get isMobile() {
    return this.device === "mobile";
  }

  @computed
  get origin() {
    return window.location.origin;
  }

  @computed
  get cookie() {
    return document.cookie;
  }

  @computed
  get from() {
    return omit(this.location, ["state"]);
  }

  @computed
  get basePath() {
    return this.location.pathname.match(BASE_PATH)?.[0];
  }

  @computed
  get returnUrl() {
    const path = this.location.state?.from?.pathname || PATHS.common.home;

    return some(INVALID_RETURN_PATHS, (invalid) => invalid.startsWith(path))
      ? PATHS.common.home
      : path;
  }

  @computed
  get queryParams() {
    return parse(this.location.search);
  }

  @computed
  get isEmbedded() {
    return this.queryParams?.embedded === "true";
  }

  @action
  goPreviousPage = async () => {
    return this.goPage(this.returnUrl);
  };

  @action
  goPage = async (path, force) => {
    if (force) window.location.href = path;
    return this.push(path, { from: this.from });
  };

  openNewPage = async (path) => {
    window.open(path, "_blank");
  };
}

export const routerStore = new RouterStore();

export const history = syncHistoryWithStore(
  createBrowserHistory(),
  routerStore
);
