import { apolloClient, CHANGE_PASSWORD_USER_MUTATION } from "~/common/apollo";
import { PATHS } from "~/common/constants";
import { captureException, toFormErrors } from "~/common/helpers";
import { store, computed, action } from "~/common/mobx.decorator";
import { authStore, notifyStore, routerStore } from "~/stores";

@store()
class CommonChangePasswordStore {
  @computed
  get show() {
    return !!authStore.authorization;
  }

  @action
  changePassword = async (
    variables,
    { setErrors },
    setShowChangePasswordDialog,
    showShowCustomMessage
  ) => {
    try {
      await apolloClient.mutate({
        mutation: CHANGE_PASSWORD_USER_MUTATION,
        variables,
      });

      if (setShowChangePasswordDialog !== false) {
        await setShowChangePasswordDialog(false);
        await showShowCustomMessage(true);
      } else {
        await notifyStore.success("$MESSAGES.CHANGE_PASSWORD_SUCCESSFUL", {
          showDialog: true,
        });
        await routerStore.goPage(PATHS.common.home);
      }
    } catch (error) {
      captureException("Change Password", error);
      setErrors(toFormErrors(error, "password"));
    }
  };
}

export const commonChangePasswordStore = new CommonChangePasswordStore();
