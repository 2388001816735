import gql from "graphql-tag";
import { COMPANY_FRAGMENT } from "../apollo.fragment";

export const UPDATE_COMPANY_MUTATION = gql`
    mutation updateCompanyMutation(
        $id: String!
        $name: String
        $mainContactName: String
        $mainContactEmail: String
        $address: String
        $zip: String
        $addressState: String
        $phone: String
        $country: String
        $city: String
        $logoSignedBlobId: String
        $description: String
    ) {
        updateCompany(
            id: $id
            name: $name
            mainContactName: $mainContactName
            mainContactEmail: $mainContactEmail
            address: $address
            zip: $zip
            addressState: $addressState
            phone: $phone
            country: $country
            city: $city
            logoSignedBlobId: $logoSignedBlobId
            description: $description
        ) {
            ...companyFragment
        }
    }
    ${COMPANY_FRAGMENT}
`;