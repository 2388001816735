import { gql } from "@apollo/client";
import { EMPLOYER_FRAGMENT } from "~/common/apollo/apollo.fragment";

export const SIGNIN_EMPLOYER_MUTATION = gql`
  mutation signInEmployer(
    $email: String!
    $password: String!
    $recaptchaToken: String
  ) {
    signInEmployer(
      email: $email
      password: $password
      recaptchaToken: $recaptchaToken
    ) {
        authToken
        employer {
          ...employerFragment
        }
    }
  }
  ${EMPLOYER_FRAGMENT}
`;


export const SEND_PASSWORD_RESET_EMPLOYER_MUTATION = gql`
  mutation sendPasswordResetEmployer($email: String!) {
    sendPasswordResetEmployer(email: $email) {
      success
    }
  }
`;

export const RESET_PASSWORD_EMPLOYER_MUTATION = gql`
  mutation resetPasswordUser(
    $token: String!
    $password: String!
    $passwordConfirmation: String!
    $recaptchaToken: String
  ) {
    resetPasswordForUser(
      recaptchaToken: $recaptchaToken
      token: $token
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      success
    }
  }
`;