import { gql } from "@apollo/client";

export const GET_FACILITIES_QUERY = gql`
  query getFacilities(
    $filter: FacilityFilters
    $limit: Int
    $keywordSearch: String
    $offset: Int
  ) {
    facilities(
      filter: $filter
      limit: $limit
      keywordSearch: $keywordSearch
      offset: $offset
    ) {
      id
      name
      active
      badges {
        id
        name
      }
    }
  }
`;

export const GET_FACILITIES_GROUP_QUERY = gql`
  query getFacilityGroups($facilityGroupId: String!) {
    facilityGroups(companyId: $facilityGroupId) {
      id
      name
      description
      companyId
      workplaceImageUrl
    }
  }
`;
