export const ONE_DAY = 24 * 60 * 60 * 1000; // ms

export const DATE_FORMAT_VIEW = "MM/DD/YYYY";
export const DATE_FORMAT_SAVE = "YYYY-MM-DD";
export const SECOND_FORMAT_SAVE = "ss";

export const SHORT_DATE_FORMAT_VIEW = "MM/YY";
export const MONTH_DAY_FORMAT_VIEW = "MM/DD";
export const MONTH_DAY_YEAR_FORMAT_VIEW = "MMM DD, YYYY";
export const MONTH_DAY_VIEW = "MMM DD";

export const DATE_TIME_HOURS_FORMAT_VIEW = "MMM DD, HH:mm";
export const DATE_TIME_FORMAT_VIEW = "MMM DD, YYYY - HH:mm";
export const DATE_TIME_FORMAT_SAVE = "DD/MM/YYYY HH:mm:ss";

export const DATE_ENCRYPT_PATTERN = /^[x]{2}\/[x]{2}\/[0-9]{4}$/;

export const TIME_FORMAT_VIEW = "HH:mm";
export const TIME_FORMAT_SAVE = "HH:mm";

export const TIME_FORMAT_VIEW_TIMECARD = "h:mma";
export const ISO1961FORMART = "YYYY-MM-DDTHH:mm:ssZ";

export const DATE_FILTER = {
  week: "week",
  month: "month",
  quarter: "quarter",
  year: "year",
};

export const RELOAD_INTERVAL_WORKER_LOCATION = 60000;
export const DATE_TIME_FORMAT_SAFARI = "YYYY-MM-DD HH:mm:ss ZZ";
