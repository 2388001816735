import { Box, Dialog, Grid, SvgIcon, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "@opus/web.core.hooks.use-translation";
import { Button } from "../button";
import { notifyDialogStyle } from "./notify.style";
import { CloseIconSvg } from "../icons";

export const NotifyDialog = ({
  title,
  hideTitle,
  hideOk,
  okText,
  cancelText,
  onCancel,
  onOk,
  okProps,
  cancelProps,
  children,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Dialog {...props} css={notifyDialogStyle}>
      <Box margin={2}>
        {!hideTitle && (
          <Box className="header" width={"calc(100% - 20px)"}>
            <Typography variant="subtitle1">{t(title)}</Typography>
          </Box>
        )}

        {onClose && (
          <Box className="close-icon" onClick={onClose}>
            <SvgIcon viewBox="0 0 14 14" component={CloseIconSvg} />
          </Box>
        )}

        <Box marginTop={3} marginBottom={2} className="content-notify">
          {children}
        </Box>
        <Grid container spacing={2}>
          {onCancel && (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onCancel}
                {...cancelProps}
              >
                {t(cancelText)}
              </Button>
            </Grid>
          )}
          {!hideOk && (
            <Grid item xs={onCancel ? 6 : 12}>
              <Button key={okProps?.disabled} onClick={onOk} {...okProps}>
                {t(okText)}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Dialog>
  );
};

NotifyDialog.defaultProps = {
  open: true,
  okText: "OK",
  cancelText: "CANCEL",
  title: "NOTIFICATION",
};
