import {
  calculateEducationPercent,
  calculateExperiencePercent,
  calculateLicensePercent,
  calculateProfilePercent,
  calculateSkillChecklistPercent,
  calculateTermPercent,
} from "~/features/recruiter-onboarding/utils/calculate-percent";
import {
  convertEducationToFormData,
  convertExperiencesToFormData,
  convertLicensesToFormData,
  convertProfileToFormData,
  convertSpecialtyToFormData,
  convertTermToFormData,
} from "~/features/recruiter-onboarding/utils/convert-to-form-data";

export const CERTIFICATIONS = {
  license: "license",
  certification: "certification",
};

export const ONBOARDING_STEP_KEYS = {
  0: "WorkerCertificationOverview",
  1: "SpecialtyOnboarding",
  2: "WorkerEducationOverview",
  3: "WorkExperienceOverview",
  4: "CombinationOnboardingPart",
  5: "UserTermsOfService",
};

export const ONBOARDING_BUILD_FORM_DATA = {
  0: convertLicensesToFormData,
  1: convertSpecialtyToFormData,
  2: convertEducationToFormData,
  3: convertExperiencesToFormData,
  4: convertProfileToFormData,
  5: convertTermToFormData,
};

export const ONBOARDING_CALCULATE_PERCENT = {
  0: calculateLicensePercent,
  1: calculateSkillChecklistPercent,
  2: calculateEducationPercent,
  3: calculateExperiencePercent,
  4: calculateProfilePercent,
  5: calculateTermPercent,
};

export const ONBOARDING_SCOPES = {
  app_installed: "app_installed",
  onboarding_workers: "onboarding_workers",
  active_workers: "active_workers",
  inactive_workers: "inactive_workers",
};

export const JOBS_SCOPES = {
  total_jobs: "total_jobs",
  total_pending_confirmation_jobs: "total_pending_confirmation_jobs",
};

export const ONBOARDING_TITLE_SCOPES_MAPPING = {
  app_installed: "ALL_TIME_APP_DOWNLOADS",
  onboarding_workers: "TOTAL_IN_ONBOARDING_WORKERS",
  active_workers: "TOTAL_ACTIVE_WORKERS",
  inactive_workers: "TOTAL_INACTIVE_WORKERS",
  total_jobs: "TOTAL_JOBS",
  total_pending_confirmation_jobs: "TOTAL_PENDING_CONFIRMATION_JOBS",
};

export const ONBOARDING_SCOPES_MAPPING = {
  total_workers: "app_installed",
  total_onboarding_workers: "onboarding_workers",
  total_active_workers: "active_workers",
  total_inactive_workers: "inactive_workers",
};
