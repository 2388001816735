import { gql } from "@apollo/client";
import {
  MESSAGE_FRAGMENT,
  MESSAGE_CONVERSATION_FRAGMENT,
} from "../apollo.fragment";

export const MARK_ALL_MESSAGES_AS_READ_MUTATION = gql`
  mutation markAllMessagesAsRead($jobId: String!, $ids: [String!]) {
    markAllMessagesAsRead(jobId: $jobId, ids: $ids) {
      success
    }
  }
`;

export const CREATE_MESSAGE_MUTATION = gql`
  mutation createMessageMutation(
    $messageConversationId: String
    $receiverType: SenderEnum
    $receiverId: String
    $resourceType: ConversationResourceEnum
    $resourceId: String
    $message: String
    $attachmentSignedBlobId: String
  ) {
    createMessage(
      messageConversationId: $messageConversationId
      receiverType: $receiverType
      receiverId: $receiverId
      resourceType: $resourceType
      resourceId: $resourceId
      message: $message
      attachmentSignedBlobId: $attachmentSignedBlobId
    ) {
      objectName
      message {
        ...messageFragment
      }
      messageConversation {
        ...messageConversationFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
  ${MESSAGE_CONVERSATION_FRAGMENT}
`;

export const CREATE_MESSAGE_CONVERSATION_MUTATION = gql`
  mutation createMessageConversationMutation(
    $conversationName: String
    $conversationType: ConversationTypeEnum
    $participants: [JSON!]
    $resourceType: ConversationResourceEnum
    $resourceId: String
  ) {
    createMessageConversation(
      conversationName: $conversationName
      conversationType: $conversationType
      participants: $participants
      resourceType: $resourceType
      resourceId: $resourceId
    ) {
      ...messageConversationFragment
    }
  }
  ${MESSAGE_CONVERSATION_FRAGMENT}
`;
