import { includes, isEmpty } from "lodash";
import {
  GET_CATEGORIES,
  GET_MASTER_DATA_QUERY,
  GET_STATES_QUERY,
  apolloClient,
} from "~/common/apollo";
import { store, observable, action, computed } from "~/common/mobx.decorator";
import { appStore } from "./app.store";
import { GET_FACILITIES_QUERY } from "~/common/apollo/queries/facility.query";
import { notifyStore } from "./notify.store";

@store()
class MasterStore {
  @observable categories = [];
  @observable specialtyOptions = [];
  @observable noBadgeSpecialtyOptions = [];
  @observable facilities = [];
  @observable noBadgesFacilities = [];
  @observable masterData = {};
  @observable states = [];
  @observable professionOptions = [];

  @computed
  get category() {
    return this.categories?.[0];
  }

  @computed
  get frequencyMasterData() {
    const frequency = appStore.masterData?.save_filter?.frequency;
    if (isEmpty(frequency)) {
      return [];
    }

    return Object.entries(frequency).map(([key, value]) => ({
      key,
      value: value,
      label: value,
    }));
  }

  @computed
  get requirements() {
    return this.masterData?.requirements?.map((item) => ({
      value: item,
      label: item,
    }));
  }

  @computed
  get certificationsLicenses() {
    return this.masterData?.combineCertificationsAndLicenses?.map((item) => ({
      value: item?.id?.toString(),
      label: item.name,
    }));
  }

  @computed
  get licenses() {
    return this.masterData?.licenses?.map((item) => ({
      value: item.id?.toString(),
      label: item.name,
    }));
  }

  @computed
  get certifications() {
    return this.masterData?.certifications?.map((item) => ({
      value: item?.id?.toString(),
      label: item?.name,
    }));
  }

  @computed
  get licenseStates() {
    return this.states.map((item) => ({
      value: item.id?.toString(),
      label: item.name,
    }));
  }

  @computed
  get shiftTypes() {
    const shiftTypes = appStore.masterData?.save_filter?.shift_type;
    if (isEmpty(shiftTypes)) {
      return [];
    }

    return Object.entries(shiftTypes).map(([key, value]) => ({
      key,
      value: key,
      label: value,
    }));
  }

  @computed
  get assignmentDeclineReasons() {
    const reasons = appStore.masterData?.assignment_decline_reasons;
    if (isEmpty(reasons)) {
      return [];
    }

    return reasons.map((item) => ({
      value: item,
      label: item,
    }));
  }

  @computed
  get jobPlacementOpitons() {
    return this.masterData?.jobPlacementScopes;
  }

  @action
  fetchCategories = async (force = false) => {
    const response = await apolloClient.query({ query: GET_CATEGORIES });
    this.categories = response.data?.categories;

    this.professionOptions = response.data?.categories?.map(
      ({ id, name, specialties }) => ({
        label: name,
        value: id,
        specialties,
      })
    );

    this.specialtyOptions = this.categories?.[0]?.specialties?.map(
      ({ id, name }) => {
        return {
          label: name,
          value: id,
        };
      }
    );

    this.noBadgeSpecialtyOptions = this.categories?.[0]?.specialties
      ?.filter((item) => isEmpty(item?.badges) || item?.badges?.length <= 0)
      .map(({ id, name }) => {
        return {
          label: name,
          value: id,
        };
      });
  };

  @action
  findDisciplineBySpecialtyId = (specialtyID) => {
    const disciplineObject = this.professionOptions.find((item) =>
      includes(
        item?.specialties?.map((sub_item) => sub_item?.id),
        specialtyID
      )
    );
    return disciplineObject;
  };

  @action
  fetchFacilities = async () => {
    try {
      const batchSize = 200;
      let offset = 0;
      let responseCount = batchSize;
      let array = [];

      while (responseCount === batchSize) {
        const response = await apolloClient.query({
          query: GET_FACILITIES_QUERY,
          variables: {
            limit: batchSize,
            offset: offset,
            filter: {
              active: true,
            },
          },
        });

        const fetchedData = response?.data?.facilities?.map((item) => ({
          id: item?.id,
          name: item?.name,
        }));
        responseCount = fetchedData?.length;

        if (fetchedData && fetchedData?.length > 0) {
          // Append the fetched data to the calendarJobs array
          array = [...array, ...fetchedData];
          offset += batchSize;
        }
      }
      this.facilities = array;
      this.noBadgesFacilities = array
        ?.filter((item) => isEmpty(item?.badges) || item?.badges?.length <= 0)
        .map(({ id, name }) => ({
          key: id,
          value: id,
          label: name,
        }));
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  fetchMasterData = async (force = false) => {
    if (force || !isEmpty(this.masterData)) {
      return;
    }
    const response = await apolloClient.query({ query: GET_MASTER_DATA_QUERY });
    this.masterData = response.data?.masterData;
  };

  @action
  fetchStates = async (force = false) => {
    if (force || !isEmpty(this.states)) {
      return;
    }
    const response = await apolloClient.query({
      query: GET_STATES_QUERY,
      variables: { countryCode: "US" },
    });
    this.states = response.data?.states;
  };

  @action
  fetchEmployerInfo = async (force = false) => {
    if (force || !isEmpty(this.masterData)) {
      return;
    }
    const response = await apolloClient.query({ query: GET_MASTER_DATA_QUERY });
    this.masterData = response.data?.masterData;
  };
}

export const masterStore = new MasterStore();
