import { yupToFormErrors } from "formik";
import * as yup from "yup";
import { DATE_ENCRYPT_PATTERN, DATE_FORMAT_SAVE } from "~/common/constants";
import validator from "validator";
import moment from "moment";
import { get, isEmpty, omit } from "lodash";
import { trimData } from "~/common/helpers";
import { date, maxDate, minDate, phone } from "~/common/validator";

const DATE_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
const DATE_ERROR = "Invalid date";
const DATE_ERROR2 = "Expired Date should be in the future.";

yup.addMethod(yup.array, "unique", function (message, path, ignoreIndex = -1) {
  return this.test("unique", message, function (list) {
    const mapper = (x) => get(x, path);
    const total = {};

    list.forEach((item, index) => {
      const value = mapper(item);
      total[value] = (total[value] || 0) + 1;
      if (ignoreIndex > 0 && index >= ignoreIndex) {
        // Don't check duplicate
      } else if (value && total[value] > 1) {
        throw this.createError({
          path: `${this.path}.[${index}].${path}`,
          message,
        });
      }
    });
    return true;
  });
});

yup.addMethod(yup.string, "minDate", function (
  message = DATE_ERROR,
  min = new Date()
) {
  return this.test("minDate", message, function (value) {
    const { path, createError } = this;

    const error = minDate(min)(value);

    return error ? createError({ path, message }) : true;
  });
});

yup.addMethod(yup.string, "validDate", function (message = DATE_ERROR) {
  return this.test("validDate", message, function (value) {
    const { path, createError } = this;
    const error = value && date(value);

    return error ? createError({ path, message }) : true;
  });
});

yup.addMethod(yup.string, "maxDate", function (
  message = DATE_ERROR,
  max = new Date()
) {
  return this.test("maxDate", message, function (value) {
    const { path, createError } = this;

    const error = maxDate(max)(value);

    return error ? createError({ path, message }) : true;
  });
});

export const LicenseSchema = yup.object().shape({
  licenseState: yup.string().trim().required("Required"),
  licenseNumber: yup
    .string()
    .trim()
    .required("Required")
    .max(100, "Maximum is 100 characters"),
  expirationDate: yup
    .string()
    .trim()
    .nullable()
    .required("Required")
    .matches(DATE_REGEX, DATE_ERROR)
    .minDate(DATE_ERROR2),
});

export const CertificationSchema = yup.object().shape({
  licenseDiscipline: yup.string().trim().required("Required"),
  licenseNumber: yup
    .string()
    .nullable()
    .trim()
    .max(100, "Maximum is 100 characters"),
  expirationDate: yup
    .string()
    .trim()
    .nullable()
    .required("Required")
    .matches(DATE_REGEX, DATE_ERROR)
    .minDate(DATE_ERROR2),
});

export const ReferenceSchema = yup.object().shape({
  facilityName: yup
    .string()
    .trim()
    .required("Required")
    .max(60, "Maximum is 60 characters"),
  contactFullName: yup.string().trim().required(),
  contactEmail: yup.string().nullable().email(),
  contactPhone: yup
    .string()
    .trim()
    .required("Required")
    .test(
      "phone",
      "Invalid phone number",
      (value) => value && validator.isMobilePhone(value)
    ),
  jobTitle: yup.string().trim().required("Required"),
});

export const EducationSchema = yup.object().shape({
  schoolName: yup
    .string()
    .trim()
    .required("Required")
    .max(100, "Maximum is 100 characters"),
  degreeName: yup
    .string()
    .trim()
    .required("Required")
    .max(100, "Maximum is 100 characters"),
  major: yup.string().trim().nullable().required("Required"),
  degreeDate: yup
    .string()
    .trim()
    .required("Required")
    .nullable()
    .matches(DATE_REGEX, DATE_ERROR)
    .validDate()
    .maxDate(),
});

export const ExperienceSchema = yup.object().shape({
  facilityName: yup
    .string()
    .trim()
    .required("Required")
    .nullable()
    .max(60, "Maximum is 60 characters"),
  unitSpecialty: yup.string().trim().required("Required"),
  discipline: yup.string().trim().required("Required"),
  startDate: yup
    .string()
    .trim()
    .required("Required")
    .nullable()
    .matches(DATE_REGEX, DATE_ERROR)
    .validDate()
    .maxDate("Start date can't be in the future"),
  endDate: yup
    .mixed()
    .when(
      ["startDate", "currentlyEmployed"],
      (startDate, currentlyEmployed) => {
        if (!currentlyEmployed) {
          return yup
            .string()
            .nullable()
            .required("Required")
            .matches(DATE_REGEX, DATE_ERROR)
            .validDate()
            .test("date_range", "Invalid date range", (value) => {
              return moment(startDate, DATE_FORMAT_SAVE).isBefore(
                moment(value, DATE_FORMAT_SAVE)
              );
            });
        }
      }
    ),
});

export const QuizAnswerSchema = yup.object().shape({
  quizAnswerId: yup.string().trim().required("Required"),
  reason: yup
    .string()
    .nullable()
    .when(["requireReason"], {
      is: (requireReason) => !!requireReason,
      then: yup
        .string()
        .trim()
        .required("Required")
        .max(255, "Maximum is 255 characters"),
    }),
});

export const WorkAuthorizationSchema = yup.object().shape({
  socialSecurityNumber: yup
    .string()
    .nullable()
    .required("Required")
    .test("ssn", "Invalid social security number", (value) => {
      const expression = /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
      const encryptPattern = /^[x]{3}-[x]{2}-[0-9]{4}$/;
      return !value || encryptPattern.test(value) || expression.test(value);
    }),
  dateOfBirth: yup
    .string()
    .required("Required")
    .nullable()
    .test("birthDay", "Date of birth must be 18 years or older", (value) => {
      if (!value || DATE_ENCRYPT_PATTERN.test(value)) {
        return true;
      }

      const date = moment(value, DATE_FORMAT_SAVE);
      return (
        date.isValid() &&
        date.isSameOrBefore(moment().subtract(18, "years"), "day")
      );
    })
    .validDate(),
});

export const AddressSchema = yup.object().shape({
  street: yup.string().trim().required("Required"),
  city: yup.string().trim("Required"),
  state: yup.string().trim("Required"),
  zipcode: yup.string().trim("Required"),
  country: yup.string().trim("Required"),
});

export const EmergencyContactSchema = yup.object().shape({
  contactPhone: yup
    .string()
    .nullable()
    .test("phonePattern", "Invalid Phone", (value) => {
      if (!value || value === "+" || value === "+1") {
        return true;
      }

      return phone(value) ? false : true;
    }),
});

export const PreferredLocationSchema = yup.object().shape({
  preferredWorkingState: yup.string().nullable(),
});

export const LicenseOverviewSchema = yup.object().shape({
  workerLicenses: yup
    .array()
    .of(yup.lazy((value) => (!value._destroy ? LicenseSchema : yup.object()))),
  workerCertifications: yup
    .array()
    .of(
      yup.lazy((value) =>
        !value._destroy ? CertificationSchema : yup.object()
      )
    ),
});

export const ExperienceOverviewSchema = yup.object().shape({
  workExperiences: yup.mixed().when(["__hasResumes"], (__hasResumes) =>
    yup.array().of(
      yup.lazy((value) => {
        const isEmptyExperience = isEmpty(
          trimData(
            omit(value, ["id", "createdAt"]),
            (value) => !!value && !isEmpty(value)
          )
        );
        return (!__hasResumes || !isEmptyExperience) && !value._destroy
          ? ExperienceSchema
          : yup.mixed().nullable();
      })
    )
  ),
});

export const EducationOverviewSchema = yup.object().shape({
  workerEducations: yup
    .array()
    .of(yup.lazy((value) => (value._destroy ? yup.object() : EducationSchema))),
});

export const ReferenceOverviewSchema = yup.object().shape({
  workerReferences: yup.array().of(
    yup.lazy((value, options) => {
      const isEmptyReference = isEmpty(
        trimData(
          omit(value, ["id", "createdAt"]),
          (value) => !!value && !isEmpty(value)
        )
      );
      const firstItem = options.path === "workerReferences[0]";
      return firstItem || !isEmptyReference
        ? ReferenceSchema
        : yup.mixed().nullable();
    })
  ),
});

export const VerificationOverviewSchema = yup.object().shape({
  termsOfServiceId: yup
    .boolean()
    .required("The terms and eligibility must be accepted.")
    .oneOf([true], "The terms and eligibility must be accepted."),
  workerQuizAnswers: yup.array(QuizAnswerSchema).min(1),
});

export const ProfileOverviewSchema = yup.object().shape({
  workingAuthorization: WorkAuthorizationSchema.required("Required"),
  workerAddress: AddressSchema.required("Required"),
  workingPreferredLocations: yup
    .array()
    .of(
      yup.lazy((_, options) =>
        options.path === "workingPreferredLocations[0]"
          ? PreferredLocationSchema
          : yup.object().nullable()
      )
    )
    .unique("Duplicate preferred destination", "preferredWorkingState", 3),
  emergencyContact: EmergencyContactSchema.nullable(),
});

export const WorkerSpecialtySchema = yup.object().shape({
  completedChecklist: yup
    .boolean()
    .required("Required")
    .oneOf([true], "The skill checklist must be completed."),
});

export const SkillChecklistOverviewSchema = yup.lazy((value) => {
  return value.__add || value.__delete || value.__update
    ? yup.object()
    : yup.object().shape({
        workerSpecialties: yup
          .array()
          .of(
            yup.lazy((value, options) => {
              return !value.id || value._destroy
                ? yup.object()
                : WorkerSpecialtySchema;
            })
          )
          .min(1, "Your specialties must have at least 1 item"),
      });
});

export const validateLicenses = async (formData) => {
  const yupErrors = await LicenseOverviewSchema.validate(formData, {
    abortEarly: false,
  }).catch((error) => error);
  const errors = yupToFormErrors(yupErrors);

  return errors;
};

export const validateSkillSchecklist = async (formData) => {
  const yupErrors = await SkillChecklistOverviewSchema.validate(formData, {
    abortEarly: false,
  }).catch((error) => error);
  const errors = yupToFormErrors(yupErrors);

  return errors;
};

export const validateExperiences = async (formData) => {
  const yupErrors = await ExperienceOverviewSchema.validate(formData, {
    abortEarly: false,
  }).catch((error) => error);
  const errors = yupToFormErrors(yupErrors);

  return errors;
};

export const validateEducations = async (formData) => {
  const yupErrors = await EducationOverviewSchema.validate(formData, {
    abortEarly: false,
  }).catch((error) => error);

  const errors = yupToFormErrors(yupErrors);
  return errors;
};

export const validateReferences = async (formData) => {
  const yupErrors = await ReferenceOverviewSchema.validate(formData, {
    abortEarly: false,
  }).catch((error) => error);

  const errors = yupToFormErrors(yupErrors);
  return errors;
};

export const validateTerms = async (formData) => {
  const yupErrors = await VerificationOverviewSchema.validate(formData, {
    abortEarly: false,
  }).catch((error) => error);

  const errors = yupToFormErrors(yupErrors);
  return errors;
};

export const validateProfile = async (formData) => {
  const yupErrors = await ProfileOverviewSchema.validate(formData, {
    abortEarly: false,
  }).catch((error) => error);

  const errors = yupToFormErrors(yupErrors);
  return errors;
};
