import { gql } from "@apollo/client";
import { RECRUITER_FRAGMENT } from "../apollo.fragment";

export const SIGNIN_SALESFORCE_RECRUITER_MUTATION = gql`
  mutation signInSalesforceRecruiter($jwtToken: String!, $companyId: String!) {
    signInSalesforceRecruiter(jwtToken: $jwtToken, companyId: $companyId) {
      authToken
      notificationChannel
      recruiter {
        ...recruiterFragment
      }
    }
  }

  ${RECRUITER_FRAGMENT}
`;

export const SIGNOUT_RECRUITER_MUTATION = gql`
  mutation signOutRecruiter {
    signOutEmployer {
      success
    }
  }
`;
