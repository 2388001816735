import { isEmpty } from "lodash";
import { apolloClient } from "~/common/apollo";
import {
  CREATE_MESSAGE_CONVERSATION_MUTATION,
  CREATE_MESSAGE_MUTATION,
} from "~/common/apollo/mutations/message.mutation";
import {
  GET_MESSAGES_QUERY,
  GET_MESSAGE_CONVERSATIONS_QUERY,
  GET_MESSAGE_CONVERSATION_QUERY,
} from "~/common/apollo/queries/message.query";
import { datetimeComparison } from "~/common/helpers/time/comparisons";
import { action, computed, observable, store } from "~/common/mobx.decorator";
import { notifyStore } from "~/stores";

@store()
class MessagesStore {
  @observable messageConversations = [];
  @observable messageConversation = {};
  @observable keywordSearch = "";
  @observable messages = [];
  @observable currentConversation = {};

  @computed
  get sortedDateMessages() {
    return this.messages;
  }

  @action
  onUpdateKeyword = async (keyword) => {
    this.keywordSearch = keyword;
    this.fetchMessageConversations();
  };

  @action
  fetchMessageConversations = async () => {
    try {
      const response = await apolloClient.query({
        query: GET_MESSAGE_CONVERSATIONS_QUERY,
        variables: {
          keyword: this.keywordSearch ? this.keywordSearch : null,
          limit: 20,
          offset: 0,
        },
      });
      this.messageConversations = response?.data?.messageConversations;
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  fetchMessages = async () => {
    try {
      const response = await apolloClient.query({
        query: GET_MESSAGES_QUERY,
        variables: {
          filter: {
            messageConversationId: this.currentConversation?.id,
          },
          limit: 20,
          offset: 0,
        },
      });
      if (!isEmpty(response?.data?.messages)) {
        const sortedDateMessages = response?.data?.messages.sort((a, b) =>
          datetimeComparison(a?.createdAt, b?.createdAt)
        );
        this.messages = sortedDateMessages;
      }
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  fetchMessagesOnScroll = async () => {
    try {
      const response = await apolloClient.query({
        query: GET_MESSAGES_QUERY,
        variables: {
          filter: {
            messageConversationId: this.currentConversation?.id,
          },
          keyword: this.keyword,
          limit: 20,
          offset: this.messages?.length || 0,
        },
      });

      if (!isEmpty(response?.data?.messages)) {
        const sortedDateMessages = response?.data?.messages.sort((a, b) =>
          datetimeComparison(a?.createdAt, b?.createdAt)
        );

        this.messages = [...sortedDateMessages, ...this.messages];
      }
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  createMessageConversation = async (formData, reloadList = true) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CREATE_MESSAGE_CONVERSATION_MUTATION,
        variables: formData,
      });
      const conversation = response?.data?.createMessageConversation;
      reloadList && this.fetchMessageConversations();
      return conversation;
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  createNewMessage = async (formData) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CREATE_MESSAGE_MUTATION,
        variables: {
          ...formData,
          messageConversationId: this.currentConversation?.id,
        },
      });

      this.messages = [
        ...this.messages,
        response?.data?.createMessage?.message,
      ];

      this.fetchMessageConversations();
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  fetchMessageConversation = async (id) => {
    try {
      const response = await apolloClient.query({
        query: GET_MESSAGE_CONVERSATION_QUERY,
        variables: {
          id: id,
        },
      });
      await (this.currentConversation =
        response?.data?.messageConversation?.messageConversation);

      await new Promise((resolve) => setTimeout(resolve, 1500));
      this.fetchMessageConversations();
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  @action
  setCurrentConversation = async (conversation) => {
    this.messages = [];
    this.currentConversation = conversation;
  };

  @action
  reset = async () => {
    this.messages = [];
    this.currentConversation = null;
  };
}

export const messagesStore = new MessagesStore();
