import { flatten } from "flat";
import { isEmpty } from "lodash";
import {
  validateEducations,
  validateExperiences,
  validateLicenses,
  validateProfile,
  validateReferences,
  validateSkillSchecklist,
  validateTerms,
} from "./validate-form-data";

export const defaultFilterCondition = ([key, value]) =>
  !isEmpty(value) && !key.startsWith("__") ? true : false;

export const calculateTotalKeys = (
  formData,
  filterCondition = defaultFilterCondition
) => {
  return (
    Object.entries(flatten(formData || {})).filter(filterCondition).length || 0
  );
};

export const calculatePercent = (totalFields, totalErrors) => {
  const sumFieldAndError = totalFields + totalErrors;

  return sumFieldAndError === 0
    ? 100
    : Math.floor((totalFields * 100) / sumFieldAndError);
};

export const calculateLicensePercent = async (formData) => {
  const totalHidden =
    (formData?.workerCertifications?.length || 0) * 2 +
      formData?.workerLicenses?.length || 0 * 2;
  const errors = await validateLicenses(formData);

  const totalFields = calculateTotalKeys(formData) - totalHidden;
  const totalErrors = calculateTotalKeys(errors);

  return calculatePercent(totalFields, totalErrors);
};

export const calculateSkillChecklistPercent = async (formData) => {
  const errors = await validateSkillSchecklist(formData);

  const totalFields = formData?.workerSpecialties?.length || 0;
  const totalErrors = calculateTotalKeys(errors);

  return calculatePercent(totalFields, totalErrors);
};

export const calculateExperiencePercent = async (formData) => {
  const totalHidden = formData?.workExperiences?.length || 0;
  const errors = await validateExperiences(formData);

  const totalFields = calculateTotalKeys(formData) - totalHidden;
  const totalErrors = calculateTotalKeys(errors);

  return calculatePercent(totalFields, totalErrors);
};

export const calculateEducationPercent = async (formData) => {
  const totalHidden = formData?.workerEducations?.length || 0;
  const errors = await validateEducations(formData);

  const totalFields = calculateTotalKeys(formData) - totalHidden;
  const totalErrors = calculateTotalKeys(errors);

  return calculatePercent(totalFields, totalErrors);
};

export const calculateReferencePercent = async (formData) => {
  const totalHidden = formData?.workerReferences?.length || 0;
  const errors = await validateReferences(formData);

  const totalFields = calculateTotalKeys(formData) - totalHidden;
  const totalErrors = calculateTotalKeys(errors);

  return calculatePercent(totalFields, totalErrors);
};

export const calculateTermPercent = async (formData) => {
  const errors = await validateTerms(formData);

  const totalFields = calculateTotalKeys(formData);
  const totalErrors = calculateTotalKeys(errors);

  return calculatePercent(totalFields, totalErrors);
};

export const calculateProfilePercent = async ({ id, ...formData }) => {
  const errors = await validateProfile(formData);

  const totalFields = calculateTotalKeys(formData);
  const totalErrors = calculateTotalKeys(errors);

  return calculatePercent(totalFields, totalErrors);
};
