const { gql } = require("@apollo/client");

export const GET_CATEGORIES = gql`
  query {
    categories {
      id
      name
      description
      jobsCount
      specialties {
        id
        name
        jobsCount
        badges {
          id
          name
        }
      }
    }
  }
`;
