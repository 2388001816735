import gql from "graphql-tag";
import { COMPANY_FRAGMENT } from "../apollo.fragment";

export const GET_COMPANY_QUERY = gql`
    query getCompany($id: String!) {
        company(id: $id) {
            ...companyFragment
        }
    }
    ${COMPANY_FRAGMENT}
`;
