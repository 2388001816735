import gql from "graphql-tag";

export const GET_APP_INIT_QUERY = gql`
  query getAppInit($workerId: String!) {
    appInit(workerId: $workerId) {
      companyConfig {
        livechatIntegration {
          accountId
        }
      }
    }
  }
`;

export const GET_APP_CONFIG_QUERY = gql`
  query getAppInfo {
    appInit {
      company {
        mainContactEmail
        phone
        id
        name
        city
        addressState
        zip
        country
        logoUrl
      }
      companyConfig {
        termUrl
        privacyPolicyUrl
        privacyPolicyContent
        androidStoreUrl
        universalAppUrl
        instagramUrl
        facebookUrl
        linkedInUrl
        tiktokUrl
        importWorkerTemplateUrl
      }
      masterData
    }
  }
`;

export const GET_MASTER_DATA_QUERY = gql`
  query getMasterData {
    masterData {
      certifications
      combineCertificationsAndLicenses
      licenses
      requirements
      shiftTypes
      jobPlacementScopes
    }
  }
`;

export const GET_STATES_QUERY = gql`
  query getStates($countryCode: String!) {
    states(countryCode: $countryCode) {
      id
      name
      stateCode
    }
  }
`;
