import { createMuiTheme } from "@material-ui/core";

export const recruiterTheme = createMuiTheme({
  overrides: {
    MuiListItem: {
      root: {
        color: "#353535",
        fontSize: "14px",
        fontWeight: 800,
        letterSpacing: "0.1px",
        "&.Mui-selected": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#141548",
        whiteSpace: "nowrap",
        fontWeight: 800,
        "&$focused": {
          color: "#141548",
        },
      },
    },

    MuiFormControlLabel: {
      label: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "0.2px",
        color: "#06163A",
      },
    },
    MuiInputBase: {
      root: {
        color: "#24444E",
        fontWeight: 400,
        fontSize: "14px",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px) scale(0.86)",
      },
      asterisk: {
        color: "#FF2727",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "transparent !important",
      },
    },
    MuiDialog: {
      container: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
    },
    MuiButtonGroup: {
      groupedOutlinedPrimary: {
        "&:hover": {
          borderColor: "#99E1E1",
        },
      },
    },
    MuiButton: {
      root: {
        height: "48px",
        background: "#1AB9D3",
        borderRadius: "48px",
        border: "none",
        fontWeight: 700,
        fontSize: "15px",
        textTransform: "unset",
      },
      outlinedPrimary: {
        border: "2px solid #1AB9D3",
        color: "#1AB9D3",
        fontWeight: 700,
        fontSize: "15px",
        backgroundColor: "#FFFFFF",
        "&:hover": {
          border: "2px solid #1AB9D3",
        },
      },
      containedPrimary: {
        color: "#f8f8f8",
        backgroundColor: "#1AB9D3",
        boxShadow: "none",
        border: "none",
        borderRadius: "48px",
        "&:hover": {
          color: "#000146",
          backgroundColor: "#9AE8F3 !important",
          boxShadow: "none",
        },
      },

      sizeLarge: {
        height: "48px",
      },
      sizeSmall: {
        height: "32px",
      },
    },
    MuiTab: {
      root: {
        textTransform: "unset",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#99E1E1",
      },
    },
    MuiPickersCalendarHeader: {
      switchViewDropdown: {
        color: "#868686",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "#F6FBFF",
        border: "1px solid #C2DAF0",
        color: "#06163A",
      },
      deleteIcon: {
        color: "#FF2727",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#ffffff",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "1px solid #C2DAF0",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#7E7E80",
      },
      colorSecondary: {
        "&.Mui-checked": {
          color: "#1AB9D3",
          "& path": {
            stroke: "white",
          },
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: { backgroundColor: "red" },
    },
    MuiAvatarGroup: {
      avatar: {
        border: "2px solid #ffffff",
        marginLeft: "-8px",
        fontSize: "14px",
        fontWeight: 700,
        width: "32px",
        height: "32px",
      },
    },
    MuiTypography: {
      root: {
        color: "#333335",
      },
    },
  },
  spacing: 8,
  palette: {
    primary: {
      light: "#E3F8F4",
      main: "#141548",
      sub: "#729DFF",
    },
    secondary: {
      light: "#f8fcfc",
      main: "#1AB9D3",
      sub: "#E9FBFD",
    },
    error: {
      main: "#fe7d74",
    },
    text: {
      primary: "#000000",
      secondary: "#03606A",
      tertiary: "#7E7E80",
      disabled: "#AEAEAE",
      blue: "#1C5CDB",
      hint: "#868686",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
      light: "rgba(130, 206, 206, 0.5)",
      element: {
        dark: "#C2DAF0",
        medium: "#DDEFFF",
        light: "#EDF7FF",
        veryLight: "#F6FBFF",
      },
      app: {
        light: "#ffffff",
        dark: "#06163A",
      },
    },
    action: {
      active: "#868686",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    content: {
      veryDark: "#3C3C3C",
      dark: "#696969",
      medium: "#D1D1D1",
      light: "#E6E6E6",
      veryLight: "#F8F8F8",
    },
    hyperlink: {
      dark: "#0C4AC2",
      medium: "#2C6EEE",
      light: "#9ABCFF",
    },
    status: {
      success: {
        dark: "#309102",
        medium: "#3BB700",
        light: "#A5FB7C",
      },
      error: {
        dark: "#B9431F",
        medium: "#FF2727",
        light: "#FF9090",
      },
      warning: {
        dark: "#DE662A",
        medium: "#FF9B04",
        light: "#FFD9A1",
      },
    },
    other: {
      dark: "#B77B2E",
      medium: "#F3E1C5",
      light: "#FFF9F0",
    },
  },
  shape: {
    borderRadius: 10,
  },
  borders: [
    "unset",
    "solid 1px #e5e5e5",
    "solid 1px rgba(130, 206, 207, 0.1)",
    "solid 1px #82CECE",
  ],
  typography: {
    fontFamily: ["P22 Mackinac Pro"],
    h6: {
      fontWeight: "350",
      fontSize: "18px",
      letterSpacing: "0.2px",
    },
    h5: {
      fontWeight: "350",
      fontSize: "20px",
      letterSpacing: "0.2px",
    },
    h4: {
      fontWeight: "350",
      fontSize: "26px",
      letterSpacing: "0.2px",
    },
    h3: {
      fontWeight: "350",
      fontSize: "32px",
      letterSpacing: "0.2px",
    },
    h2: {
      fontWeight: "350",
      fontSize: "56px",
      letterSpacing: "0.2px",
    },
    h1: {
      fontWeight: "350",
      fontSize: "56px",
      letterSpacing: "0.2px",
    },
    title1: {
      fontWeight: "700",
      fontSize: "16px",
      letterSpacing: "0.1px",
    },
    title2: {
      fontWeight: "700",
      fontSize: "14px",
      letterSpacing: "0.1px",
    },
    title3: {
      fontWeight: "700",
      fontSize: "12px",
      letterSpacing: "0.1px",
    },
    subtitle1: { fontSize: "16px", fontWeight: 500, letterSpacing: "0.1px" },
    subtitle2: { fontSize: "14px", fontWeight: 500, letterSpacing: "0.1px" },
    subtitle3: { fontSize: "12px", fontWeight: 500, letterSpacing: "0.1px" },
    body1: {
      fontWeight: "350",
      fontSize: "16px",
      letterSpacing: "0.1px",
    },
    body2: {
      fontWeight: "350",
      fontSize: "14px",
      letterSpacing: "0.1px",
    },
    body3: {
      fontWeight: "350",
      fontSize: "12px",
      letterSpacing: "0.1px",
    },
    button: { fontSize: "15px", fontWeight: "500", letterSpacing: "0" },
    caption: { fontSize: "14px", fontWeight: "700", letterSpacing: "0.4px" },
    price: { fontSize: "20px", fontWeight: "500", letterSpacing: "0.2px" },
    footnote: { fontSize: "15px", fontWeight: "500", letterSpacing: "0.1px" },
    ghostButton: { fontSize: "15px", fontWeight: "500", letterSpacing: "0" },
  },
});
