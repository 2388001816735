import { gql } from "@apollo/client";

export const RATING_FRAGMENT = gql`
  fragment ratingFragment on RatingResource {
    id
    comments
    rating
    raterType
    rater {
      ... on Worker {
        id
      }
      ... on Employer {
        id
      }
    }
    user {
      ... on Worker {
        id
      }
      ... on Employer {
        id
      }
    }
  }
`;

export const JOB_REPORT_FRAGMENT = gql`
  fragment jobReportFragment on JobReport {
    id
    shiftName
    finalPayment
    workedHours
    adjustedWorkedHours
    employerNote
    workerNote
    state
    permittedEvents
    jobReportActivities {
      id
      actionType
      startTime
      endTime
      durationInHour
    }
    job {
      id
      permittedEvents
      state
      shift {
        id
        shiftRequest {
          id
          unreadMessageCounter
        }
        facility {
          id
          name
        }
        startTime
        timezone
        location {
          id
          addressState
          city
        }
        shiftRequestId
      }
      jobPlacement {
        id
        opusStatus
        permittedEvents
      }
      worker {
        id
        avatarUrl
        firstName
        lastName
        completedJobsNumber
        workedHours
      }
      jobRatings {
        ...ratingFragment
      }
      unreadMessageCounter
    }
    workedTimesheet {
      id
      timesheetImages {
        id
        fileUrl
        filename
        imageUrls(size: ["100x100", "200x200"])
      }
    }
  }

  ${RATING_FRAGMENT}
`;

export const EMERGENCY_CONTACT_FRAGMENT = gql`
  fragment emergencyContactFragment on EmergencyContact {
    id
    contactName
    contactPhone
    contactRelationship
  }
`;

export const WORKER_ADDRESS_FRAGMENT = gql`
  fragment workerAddressFragment on WorkerAddress {
    id
    zipcode
    street
    default
    city
    state
    country
  }
`;

export const WORKER_FRAGMENT = gql`
  fragment workerFragment on Worker {
    id
    email
    firstName
    lastName
    avatarUrl
    phone
    phoneVerifiedAt
    workAuthorized
    maidenName
    company {
      companyConfig {
        companyId
        oneSignalAppId
      }
    }
    doneJobsJobReports {
      ...jobReportFragment
    }
    completedJobsNumber
    workedHours
  }
  ${JOB_REPORT_FRAGMENT}
`;

export const LOCATION_FRAGMENT = gql`
  fragment locationFragment on Location {
    id
    active
    address
    addressState
    city
    facilityId
    facilityGroupId
    geofencingRadius
    latitude
    longitude
    timezone
    name
    phone
    zipcode
    country
  }
`;

export const JOB_FRAGMENT = gql`
  fragment jobFragment on Job {
    id
    createdAt
    shiftId
    actualWorkingDuration
    state
    note
    adjustedPayment
    pausedAt
    startedAt
    filledAt
    facilityId
    permittedEvents
    workerId
    unreadMessageCounter
    maximumPayAmount
    payAmount
    adjustedPayment
    jobRatings {
      id
      rating
    }
    jobReport {
      id
      employerNote
      employerRating
      expectedJobDuration
      expectedPayment
      finalPayment
      locationAddress
      locationName
      shiftName
      workedHours
      workerId
      workerNote
      workedDate
      workerRating
      permittedEvents
      adjustedWorkedHours
    }
    worker {
      id
      avatarUrl
      firstName
      lastName
      phone
      email
    }
    shift {
      id
      payAmount
      maximumPayAmount
      startTime

      location {
        timezone
      }

      shiftRequest {
        id
        name
        state

        shiftCategory {
          id
          name
        }
      }
    }
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment companyFragment on Company {
    id
    name
    phone
    address
    addressState
    city
    country
    zip
    description
    mainContactEmail
    mainContactName
    logoUrl
  }
`;

export const WORKER_CREDENTIAL_FRAGMENT = gql`
  fragment workerCredentialFragment on WorkerCredential {
    company {
      ...companyFragment
    }
    createdAt
    expirationDate
    id
    name
    status
    worker {
      ...workerFragment
    }
  }
  ${WORKER_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

export const WORKING_AUTHORIZATION_FRAGMENT = gql`
  fragment workingAuthorizationFragment on WorkingAuthorization {
    id
    dateOfBirth
    socialSecurityNumber
  }
`;

export const WORK_EXPERIENCE_FRAGMENT = gql`
  fragment workExperienceFragment on WorkExperience {
    id
    startDate
    endDate
    facilityName
    unitSpecialty
    unitsFloatedTo
    floated
    chartingSoftwareUsed
    travelAssignment
    agency
    currentlyEmployed
    createdAt
    city
    state
    country
    patientRatio
    bedsInUnit
  }
`;

export const SALESFORCE_INTEGRATION_FRAGMENT = gql`
  fragment salesforceIntegrationFragment on SalesforceIntegration {
    id
    companyId
    businessDay
    weekending
  }
`;

export const WORKED_TIMESHEET_FRAGMENT = gql`
  fragment workedTimesheetFragment on WorkedTimesheet {
    id
    startDate
    endDate
    status
    updatedAt
  }
`;

export const ONBOARDING_PART_FRAGMENT = gql`
  fragment onboardingPartFragment on OnboardingPart {
    id
    name
    description
  }
`;
export const DIRECT_UPLOAD_FRAGMENT = gql`
  fragment directUploadFragment on DirectUpload {
    blobId
    headers
    signedBlobId
    url
  }
`;

export const WORKER_CERTIFICATION_FRAGMENT = gql`
  fragment workerCertificationFragment on WorkerCertification {
    id
    title
    licenseDiscipline
    licenseState
    licenseNumber
    compactLicense
    expirationDate
    certificationType
    createdAt
    licenseImages {
      id
      contentType
      filename
      imageUrls(size: "100x100")
    }
  }
`;

export const WORKER_REFERENCE_FRAGMENT = gql`
  fragment workerReferenceFragment on WorkerReference {
    id
    facilityName
    contactFullName
    contactEmail
    contactPhone
    waitToContact
    jobTitle
    createdAt
  }
`;
export const IMAGE_FILE_FRAGMENT = gql`
  fragment imageFileFragment on File {
    id
    fileUrl
    contentType
  }
`;

export const FILE_FRAGMENT = gql`
  fragment fileFragment on File {
    id
    filename
    fileUrl
    contentType
  }
`;

export const WORKER_EDUCATION_FRAGMENT = gql`
  fragment workerEducationFragment on WorkerEducation {
    id
    schoolName
    major
    state
    city
    country
    degreeName
    degreeDate
  }
`;

export const LICENSE_IMAGES_FRAGMENT = gql`
  fragment licenseImagesFragment on File {
    id
    fileUrl
    contentType
  }
`;

export const WORKER_CETIFICATION_OVERVIEW_FRAGMENT = gql`
  fragment workerCertificationOverviewFragment on WorkerCertificationOverview {
    id
    workerCertifications {
      ...workerCertificationFragment
    }
  }

  ${WORKER_CERTIFICATION_FRAGMENT}
`;

export const WORKER_REFERENCE_OVERVIEW_FRAGMENT = gql`
  fragment workerReferenceOverviewFragment on WorkerReferenceOverview {
    id
    workerReferences {
      ...workerReferenceFragment
    }
  }

  ${WORKER_REFERENCE_FRAGMENT}
`;

export const WORKER_EDUCATION_OVERVIEW_FRAGMENT = gql`
  fragment workerEducationOverviewFragment on WorkerEducationOverview {
    id
    workerEducations {
      ...workerEducationFragment
    }
  }

  ${WORKER_EDUCATION_FRAGMENT}
`;

export const WORK_EXPERIENCE_OVERVIEW_FRAMENT = gql`
  fragment workExperienceOverview on WorkExperienceOverview {
    id
    discipline
    primarySpecialty
    secondarySpecialty
    yearsOfExperience
    workExperiences {
      ...workExperienceFragment
    }
  }

  ${WORK_EXPERIENCE_FRAGMENT}
`;

export const WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
  fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
    ... on WorkerCertificationOverview {
      ...workerCertificationOverviewFragment
    }

    ... on WorkExperienceOverview {
      ...workExperienceOverview
    }

    ... on WorkerReferenceOverview {
      ...workerReferenceOverviewFragment
    }

    ... on WorkerEducationOverview {
      ...workerEducationOverviewFragment
    }

    ... on UserTermsOfService {
      id
      agreedAt
      termsOfService {
        id
        url
        version
      }
    }

    ... on CombinationOnboardingPart {
      id
      workerAddress {
        ...workerAddressFragment
      }
      workingAuthorization {
        ...workingAuthorizationFragment
      }
      workingPreferredLocations {
        id
        preferredWorkingState
      }
      emergencyContact {
        ...emergencyContactFragment
      }
    }
  }

  ${WORKER_CETIFICATION_OVERVIEW_FRAGMENT}
  ${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
  ${WORKER_REFERENCE_OVERVIEW_FRAGMENT}
  ${WORKER_EDUCATION_OVERVIEW_FRAGMENT}
  ${WORKER_ADDRESS_FRAGMENT}
  ${WORKING_AUTHORIZATION_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
`;

export const WORKER_ONBOARDING_PART_FRAGMENT = gql`
  fragment workerOnboardingPartFragment on WorkerOnboardingPart {
    id
    integrationConfigurations
    integrationObject
    state
    integration {
      ...workerOnboardingPartIntegrationFragment
    }
  }

  ${WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`;

export const REFERRED_TRAVELER_FRAGMENT = gql`
  fragment referredTravelerFragment on ReferredTraveler {
    id
    firstName
    lastName
    email
    phone
    relationship
    discipline
  }
`;

export const RECRUITER_FRAGMENT = gql`
  fragment recruiterFragment on Recruiter {
    companyId
    email
    id
    name
    phone
    title
    avatarUrl
    company {
      ...companyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const EMPLOYER_FRAGMENT = gql`
  fragment employerFragment on Employer {
    id
    email
    firstName
    lastName
    phone
    title
    agreedToTerms
    avatarUrl
    changePasswordRequired
    employerAddress {
      id
      address1
      address2
      city
      state
      zipcode
      timezone
    }
    company {
      ...companyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const BADGE_FRAGMENT = gql`
  fragment badgeFragment on Badge {
    id
    name
    description
    active
    iconUrl
    objectableId
    objectableType
    objectable
    createdAt
    objectable {
      ... on Facility {
        id
        name
        active
        badges {
          id
          name
        }
      }
      ... on Specialty {
        id
        name
      }
    }
  }
`;

export const SHIFT_REQUEST_FRAGMENT = gql`
  fragment shiftRequestFragment on ShiftRequest {
    id
    name
    state
    companyId
    assignedEmployerId
    invitedWorkerIds
    description
    requirements
    badgeRequirements
    employerId
    facilityId
    facilityGroupId
    shiftCategoryId
    permittedEvents
    generalDetails
    shifts {
      id
      approvedAt
      breakTimeDuration
      breakTimeNumber
      createdAt
      cancelledAt
      duration
      jobsNumber
      state
      payAmount
      maximumPayAmount
      paymentType
      startTime
      endTime
      shiftRequestId
      fillRate
      permittedEvents
      timezone
      location {
        id
        active
        address
        addressState
        city
        facilityGroupId
        geofencingRadius
        latitude
        longitude
        phone
        zipcode
        timezone
        country
      }
      jobs {
        id
        createdAt
        shiftId
        actualWorkingDuration
        state
        note
        adjustedPayment
        pausedAt
        startedAt
        filledAt
        permittedEvents
        unreadMessageCounter
        worker {
          id
          email
          avatarUrl
          firstName
          lastName
          phone
        }
        jobRatings {
          id
          rating
        }
      }
    }
    shiftCategory {
      id
      name
      description
    }
  }
`;

export const SHIFT_FRAGMENT = gql`
  fragment shiftFragment on Shift {
    id
    approvedAt
    breakTimeDuration
    breakTimeNumber
    createdAt
    cancelledAt
    duration
    jobsNumber
    note
    state
    payAmount
    maximumPayAmount
    paymentType
    startTime
    endTime
    shiftRequestId
    fillRate
    permittedEvents
    timezone
    shiftRequest {
      id
      name
      state
      companyId
      assignedEmployerId
      invitedWorkerIds
      description
      requirements
      badgeRequirements
      employerId
      facilityId
      facilityGroupId
      shiftCategoryId
      permittedEvents
      generalDetails
    }
    shiftRequestId
    location {
      id
      active
      address
      addressState
      city
      facilityId
      facilityGroupId
      geofencingRadius
      latitude
      longitude
      phone
      zipcode
      timezone
      country
    }
    facility {
      id
      name
    }
    jobs {
      id
      createdAt
      shiftId
      actualWorkingDuration
      state
      note
      adjustedPayment
      pausedAt
      startedAt
      filledAt
      permittedEvents
      unreadMessageCounter
      worker {
        id
        email
        avatarUrl
        firstName
        lastName
        phone
      }
      jobRatings {
        id
        rating
      }
    }
  }
`;

export const MESSAGE_FRAGMENT = gql`
  fragment messageFragment on Message {
    id
    attachmentUrl
    createdAt
    message
    senderType
    senderId
    sender {
      ... on Employer {
        id
        firstName
        lastName
      }
      ... on Worker {
        id
        firstName
        lastName
      }
    }
  }
`;

export const MESSAGE_CONVERSATION_FRAGMENT = gql`
  fragment messageConversationFragment on MessageConversation {
    id
    conversationName
    lastMessage {
      ...messageFragment
    }
    unreadCount
    status
    conversationType
    createdAt
    resourceId
    resourceType
  }
  ${MESSAGE_FRAGMENT}
`;

export const JOB_PLACEMENT_FRAGMENT = gql`
  fragment jobPlacementFragment on JobPlacement {
    id
    shiftId
    status
    opusStatus
    workerId
    worker {
      id
      firstName
      lastName
      avatarUrl
    }
    permittedEvents
    jobId
    job {
      id
      permittedEvents
      unreadMessageCounter
      jobReport {
        workedHours
      }
      shift {
        id
        startTime
        endTime
        payAmount
        shiftRequestId
        facility {
          id
          name
        }
        shiftRequest {
          id
          unreadMessageCounter
          name
        }
      }
    }
    jobRatings {
      ...ratingFragment
    }
    notes
    reasonMessage
    reasonCancelled
  }
  ${RATING_FRAGMENT}
`;
