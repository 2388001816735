import moment from "moment-timezone";
import {
  DATE_TIME_FORMAT_VIEW,
  DATE_FORMAT_VIEW,
  DATE_FORMAT_SAVE,
} from "../constants";

export const dateFormat = (value, format = DATE_FORMAT_VIEW) => {
  return moment(value, "YYYY-MM-DD HH:mm:ss ZZ").format(format);
};

export const datetimeFormat = (value, format = DATE_TIME_FORMAT_VIEW) => {
  return moment(value, "YYYY-MM-DD HH:mm:ss ZZ").format(format);
};

export const toDate = (value, format = DATE_FORMAT_SAVE) => {
  return moment(value, format).toDate();
};

export const withinNextHours = (hours = 4, date) => {
  const currentDate = moment();

  // Create a moment object for the future time (2 hours from now)
  const futureDate = moment().add(hours, "hours");

  if (date && date.isAfter(currentDate) && date.isSameOrBefore(futureDate)) {
    return true;
  }
  return false;
};

export const timeByTimezone = (
  time,
  timezone,
  format = DATE_TIME_FORMAT_VIEW
) => {
  const modifyTime = moment.utc(time);
  return modifyTime.tz(timezone).format(format);
};

export const timeForView = (time, format = DATE_TIME_FORMAT_VIEW) => {
  const modifyTime = moment.utc(time);
  return modifyTime.format(format);
};

const convertTimeToUTC = (time, timezone) => {
  const utcTime =
    moment.tz(time, timezone).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
  return utcTime;
};

export const combineDateTimeInUTC = (
  time,
  selectedTime,
  timezone,
  secondSave = "00"
) => {
  const converted = convertTimeToUTC(
    `${time} ${selectedTime}:${secondSave}`,
    timezone
  );
  return converted;
};

export const isUnixTimestamp = (str) => {
  const unixTimestamp = parseInt(str, 10);
  return (
    !isNaN(unixTimestamp) && new Date(unixTimestamp).getTime() === unixTimestamp
  );
};

export const isStartInLessThan2Hours = (startTimeStr) => {
  const startTime = moment.utc(startTimeStr, "YYYY-MM-DD HH:mm:ss UTC");
  const current = moment().utc();
  const secondsDifference = startTime.diff(current, "seconds");
  return secondsDifference / 3600 < 2;
};

export const isStartInFrom2To4Hours = (startTimeStr) => {
  const startTime = moment.utc(startTimeStr, "YYYY-MM-DD HH:mm:ss UTC");
  const current = moment().utc();
  const secondsDifference = startTime.diff(current, "seconds");
  const hoursDifference = secondsDifference / 3600;
  return hoursDifference > 2 && hoursDifference <= 4;
};
