import firebase from "firebase/app";
import { store, computed } from "~/common/mobx.decorator";
import { appStore, authStore } from ".";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import { apolloClient, VERIFY_OAUTH_LOGIN } from "~/common/apollo";
import { action } from "mobx";
import { eventClient } from "@opus/web.core.lib.event-tracking";
import { BaseAdapter } from "@opus/web.core.lib.event-tracking/dist/adapters";
import { events } from "@opus/web.core.lib.event-tracking";
import { notifyStore } from "~/stores";
import { captureException, RECAPTCHA_CLIENT_KEY } from "~/common/helpers";

import axios from "axios";
axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}`;
axios.defaults.headers["APP_ID"] = process.env.REACT_APP_PROXY_API_APP_ID;
axios.defaults.headers["APP_SECRET"] =
  process.env.REACT_APP_PROXY_API_APP_SECRET;

axios.interceptors.response.use(undefined, (error) => {
  captureException("Axios Client", error);
  return Promise.reject(error);
});

const MAPPING_EVENTS = {
  ONBOARDING_LANDING_SUCCESS: "ONB_LANDING_SUCC",
  ONBOARDING_LICENSE_AND_CERTIFICATION_LANDING_SUCCESS:
    "ONB_LI_AND_CER_LAND_SUCC",
  ONBOARDING_WORK_EXPERIENCES_LANDING_SUCCESS: "ONB_W_EXP_LAND_SUCC",
  ONBOARDING_EDUCATION_LANDING_SUCCESS: "ONB_EDUCATION_LANDING_SUCC",
  ONBOARDING_REFERENCES_LANDING_SUCCESS: "ONB_REFERENCES_LANDING_SUCC",
  ONBOARDING_PERSONAL_INFO_LANDING_SUCCESS: "ONB_PERSONAL_INFO_LANDING_SUCC",
  ONBOARDING_VERIFICATION_AND_TERMS_LANDING_SUCCESS:
    "ONB_VERIFI_AND_TERMS_LAND_SUCC",
  ONBOARDING_WORK_AUTHORIZED_SUCCESS: "ONB_WORK_AUTHORIZED_SUCC",
  ONBOARDING_CLICK_UPLOAD_PHOTO_SUCCESS: "ONB_CLICK_UPLOAD_PHOTO_SUCC",
  ONBOARDING_UPLOAD_PHOTO_SUCCESS: "ONB_UPLOAD_PHOTO_SUCC",
  ONBOARDING_CLICK_ADD_ANOTHER_SUCCESS: "ONB_CLICK_ADD_ANOTHER_SUCC",
  ONBOARDING_CLICK_NEXT_STEP: "ONB_CLICK_NEXT_STEP",
  ONBOARDING_CLICK_NEXT_STEP_SUCCESS: "ONB_CLICK_NEXT_STEP_SUCC",
  ONBOARDING_VALIDATION_FAILED: "ONB_VALIDATION_FAILED",
  ONBOARDING_CLICK_COMPLETE_APPLICATION_SUCCESS: "ONB_CLICK_COMP_APP_SUCC",
  ONBOARDING_CLICK_CIRCLE_STEP_SUCCESS: "ONB_CLICK_CIRCLE_STEP_SUCC",
  HOMEPAGE_LANDING_SUCCESS: "HOMEPAGE_LANDING_SUCC",
  HOMEPAGE_CLICK_MATCHED_CITY_SUCCESS: "HOMEPAGE_CLICK_MATCHED_CITY_SUCC",
  HOMEPAGE_CLICK_SEE_MORE_CITIES_SUCCESS: "HOMEPAGE_CLICK_SEE_MORE_CITIES_SUCC",
  HOMEPAGE_CLICK_PLEASE_SUBMIT_SUCCESS: "HOMEPAGE_CLICK_PLEASE_SUBMIT_SUCC",
  HOMEPAGE_CLICK_BOOKMARK_SUCCESS: "HOMEPAGE_CLICK_BOOKMARK_SUCC",
  HOMEPAGE_CLICK_VIEW_DETAILS_SUCCESS: "HOMEPAGE_CLICK_VIEW_DETAILS_SUCC",
  HOMEPAGE_CLICK_SEE_MORE_JOBS_SUCCESS: "HOMEPAGE_CLICK_SEE_MORE_JOBS_SUCC",
  HOMEPAGE_RECOMMEND_CITIES_SUCCESS: "HOMEPAGE_RECOMMEND_CITIES_SUCC",
  HOMEPAGE_RECOMMEND_JOBS_SUCCESS: "HOMEPAGE_RECOMMEND_JOBS_SUCC",
  POPULAR_CITY_LANDING_SUCCESS: "POPULAR_CITY_LANDING_SUCC",
  POPULAR_CITY_RECOMMEND_CITIES_SUCCESS: "POPULAR_CITY_RECOMMEND_CITIES_SUCC",
  POPULAR_CITY_CLICK_MATCHED_CITY_SUCCESS:
    "POPULAR_CITY_CLICK_MATCHED_CITY_SUCC",
  POPULAR_CITY_RECOMMEND_JOBS_SUCCESS: "POPULAR_CITY_RECOMMEND_JOBS_SUCC",
  POPULAR_CITY_CLICK_PLEASE_SUBMIT_SUCCESS:
    "POPULAR_CITY_CLICK_PLEASE_SUBMIT_SUCC",
  POPULAR_CITY_CLICK_BOOKMARK_SUCCESS: "POPULAR_CITY_CLICK_BOOKMARK_SUCC",
  POPULAR_CITY_CLICK_VIEW_DETAILS_SUCCESS:
    "POPULAR_CITY_CLICK_VIEW_DETAILS_SUCC",
  SEE_MORE_JOBS_LANDING_SUCCESS: "SEE_MORE_JOBS_LANDING_SUCC",
  SEE_MORE_JOBS_RECOMMEND_JOBS_SUCCESS: "SEE_MORE_JOBS_RECOMMEND_JOBS_SUCC",
  SEE_MORE_JOBS_CLICK_PLEASE_SUBMIT_SUCCESS:
    "SEE_MORE_JOBS_CLICK_PLEASE_SUBMIT_SUCC",
  SEE_MORE_JOBS_CLICK_BOOKMARK_SUCCESS: "SEE_MORE_JOBS_CLICK_BOOKMARK_SUCC",
  SEE_MORE_JOBS_CLICK_VIEW_DETAILS_SUCCESS:
    "SEE_MORE_JOBS_CLICK_VIEW_DETAILS_SUCC",
  FIND_JOB_LANDING_SUCCESS: "FIND_JOB_LANDING_SUCC",
  FIND_JOB_RECOMMEND_JOBS_SUCCESS: "FIND_JOB_RECOMMEND_JOBS_SUCC",
  FIND_JOB_CLICK_PLEASE_SUBMIT_SUCCESS: "FIND_JOB_CLICK_PLEASE_SUBMIT_SUCC",
  FIND_JOB_CLICK_BOOKMARK_SUCCESS: "FIND_JOB_CLICK_BOOKMARK_SUCC",
  FIND_JOB_CLICK_VIEW_DETAILS_SUCCESS: "FIND_JOB_CLICK_VIEW_DETAILS_SUCC",
  FIND_JOB_CHOOSE_DISCIPLINE_SUCCESS: "FIND_JOB_CHOOSE_DISCIPLINE_SUCC",
  FIND_JOB_CHOOSE_SPECIALTY_SUCCESS: "FIND_JOB_CHOOSE_SPECIALTY_SUCC",
  FIND_JOB_CHOOSE_STATE_SUCCESS: "FIND_JOB_CHOOSE_STATE_SUCC",
  FIND_JOB_INPUT_CITY_SUCCESS: "FIND_JOB_INPUT_CITY_SUCC",
  FIND_JOB_DRAG_RADIUS_SUCCESS: "FIND_JOB_DRAG_RADIUS_SUCC",
  FIND_JOB_INPUT_WAGE_MIN_SUCCESS: "FIND_JOB_INPUT_WAGE_MIN_SUCC",
  FIND_JOB_INPUT_WAGE_MAX_SUCCESS: "FIND_JOB_INPUT_WAGE_MAX_SUCC",
  FIND_JOB_INPUT_JOB_ID_SUCCESS: "FIND_JOB_INPUT_JOB_ID_SUCC",
  FIND_JOB_RESULTS_LANDING_SUCCESS: "FIND_JOB_RES_LANDING_SUCC",
  FIND_JOB_RESULTS_RECOMMEND_JOBS_SUCCESS: "FIND_JOB_RES_RECOMMEND_JOBS_SUCC",
  FIND_JOB_RESULTS_CHOOSE_SORT_SUCCESS: "FIND_JOB_RES_CHOOSE_SORT_SUCC",
  FIND_JOB_RESULTS_CLICK_PLEASE_SUBMIT_SUCCESS:
    "FIND_JOB_RES_CLICK_PLEASE_SUBMIT_SUCC",
  FIND_JOB_RESULTS_CLICK_BOOKMARK_SUCCESS: "FIND_JOB_RES_CLICK_BOOKMARK_SUCC",
  FIND_JOB_RESULTS_CLICK_VIEW_DETAILS_SUCCESS:
    "FIND_JOB_RES_CLICK_VIEW_DETAILS_SUCC",
};

class FirebaseAdapter extends BaseAdapter {
  analytics;
  constructor(analytics) {
    super();
    this.analytics = analytics;
  }

  logEvent(type, payload) {
    const chunks = JSON.stringify(payload || {}).match(/.{1,100}/g);
    const total = chunks.length;

    const finalPayload = chunks.reduce(
      (result, item, index) => ({ ...result, [`chunk_${index}`]: item }),
      {}
    );

    const eventName = MAPPING_EVENTS[type] || type;
    this.analytics.logEvent(eventName, { num_chunks: total, ...finalPayload });
  }

  setUserId(id) {
    this.analytics.setUserId(id);
  }

  setUserProperties(properties) {
    this.analytics.setUserProperties(properties);
  }
}

if (process.env.REACT_APP_FIREBASE_CONFIG) {
  firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
  firebase.auth().signInAnonymously();
  const analytics = firebase.analytics();
  firebase.firestore().settings({
    ignoreUndefinedProperties: true,
    experimentalForceOwningTab: false,
  });
  firebase.firestore().enablePersistence();

  eventClient.addAdapter(new FirebaseAdapter(analytics));
}

@store()
class FireStore {
  @computed
  get db() {
    return firebase.firestore();
  }

  @computed
  get fid() {
    return btoa(`${appStore.id}/${authStore.id || appStore._uniqueId}`);
  }

  @action
  clear = async (collection) => {
    return this.db.collection(collection).doc(this.fid).delete();
  };

  @action
  getDoc = async (collection) => {
    return this.db.collection(collection).doc(this.fid);
  };

  @action
  getData = async (collection) => {
    const docRef = this.db.collection(collection).doc(this.fid);
    const doc = await docRef.get();
    return doc.data();
  };

  @action
  signInWithOauth = (history, providerData) => {
    new Promise((resolve, reject) => {
      window.grecaptcha.ready(async () => {
        try {
          eventClient.logEvent(new events.SigninClickSuccessEvent());
          const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
            action: "verifySocialAuthentication",
          });
          let updatedVariables = {
            recaptchaToken: token,
            clientToken: providerData?.clientToken,
            email: providerData?.email,
            providerName: providerData?.providerName,
            clientId: providerData?.clientId,
            code: providerData?.code,
            redirectUri: providerData?.redirectUri,
          };
          const {
            data: { verifySocialAuthentication },
          } = await apolloClient.mutate({
            mutation: VERIFY_OAUTH_LOGIN,
            variables: updatedVariables,
          });
          const {
            authToken,
            worker,
            notificationChannel,
            userSocialAuthentication,
            emailHasAccount,
          } = verifySocialAuthentication;
          const workerSocialInfo = {
            socialAuthenticationId: userSocialAuthentication?.id,
            email: userSocialAuthentication?.email,
            firstName: providerData?.workerSocialFirstName,
            lastName: providerData?.workerSocialLastName,
          };
          if (authToken) {
            await authStore.changeProfile(worker);
            await authStore.changeToken(authToken);
            await notifyStore.initOneSignal(
              worker?.company?.companyConfig,
              worker,
              notificationChannel
            );
            eventClient.logEvent(new events.SigninHomeSuccessEvent());
          } else {
            if (!emailHasAccount) {
              history.push("/link_options", { workerSocialInfo });
            } else {
              history.push("/link_account", { workerSocialInfo });
            }
          }
          resolve();
        } catch (error) {
          captureException("Signin", error);
          eventClient.logEvent(new events.SigninClickFailEvent());
          reject(error.message);
        }
      });
    });
  };
}

export const fireStore = new FireStore();
