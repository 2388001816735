import { eventClient, events } from "@opus/web.core.lib.event-tracking";
import { apolloClient, SIGNIN_EMPLOYER_MUTATION } from "~/common/apollo";
import { isPasswordExpiredError, RECAPTCHA_CLIENT_KEY } from "~/common/helpers";
import { store, action, observable } from "~/common/mobx.decorator";
import { authStore, notifyStore } from "~/stores";

@store()
class CommonSigninStore {
  @observable changePasswordRequired = false;
  @observable passwordDialog = false;

  @action
  login = async (variables, { setErrors }) => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(async () => {
        try {
          eventClient.logEvent(new events.SigninClickSuccessEvent());
          const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
            action: "signInEmployer",
          });
          let loginVariables = {
            recaptchaToken: token,
            ...variables,
          };

          const {
            data: { signInEmployer },
          } = await apolloClient.mutate({
            mutation: SIGNIN_EMPLOYER_MUTATION,
            variables: loginVariables,
          });

          const { authToken, employer } = signInEmployer;

          this.changePasswordRequired = employer?.changePasswordRequired;

          if (employer?.changePasswordRequired) {
            await authStore.setShowChangePasswordDialog(true);
          } else {
            await authStore.changeProfile(employer);
          }
          await authStore.changeToken(authToken);
          this.passwordDialog = false;
          eventClient.logEvent(new events.SigninHomeSuccessEvent());
          resolve();
        } catch (error) {
          if (isPasswordExpiredError(error)) {
            this.passwordDialog = true;
            authStore.setShowChangePasswordDialog(false);
            reject(error?.message);
          } else {
            notifyStore.error(error.message);
            // const errors = toFormErrors(error) || {};
            // if (errors?.hasOwnProperty("__ERROR") || errors?.hasOwnProperty("base")) {
            //   notifyStore.error(error.message);
            // } else {
            //   setErrors(errors);
            // }
            reject(error);
          }
        }
      });
    });
  };

  @action
  togglePasswordDialog = async (value) => {
    this.passwordDialog = value;
  };
}

export const commonSigninStore = new CommonSigninStore();
