import gql from "graphql-tag";
import { SHIFT_REQUEST_FRAGMENT } from "../apollo.fragment";

export const UPDATE_SHIFT_REQUEST_MUTATION = gql`
    mutation updateShiftRequestMutation(
        $id: String!
        $name: String
        $description: [String!]
        $badgeRequirements: [String!]
        $requirements: [String!]
        $shiftCategoryId: String
        $assignedEmployerId: String
        $payCurrency: String
        $state: String
        $invitedWorkerIds: [String!]
        $facilityId: String
        $useSmartRanking: Boolean
        $shiftsAttributes: [ShiftArgument!]
        $generalDetails: ShiftRequestGeneralDetailsArgument
    ) {
        updateShiftRequest(
            id: $id
            name: $name
            description: $description
            badgeRequirements: $badgeRequirements
            requirements: $requirements
            shiftCategoryId: $shiftCategoryId
            assignedEmployerId: $assignedEmployerId
            payCurrency: $payCurrency
            state: $state
            invitedWorkerIds: $invitedWorkerIds
            facilityId: $facilityId
            useSmartRanking: $useSmartRanking
            shiftsAttributes: $shiftsAttributes
            generalDetails: $generalDetails
        ) {
            ...shiftRequestFragment
        }
    }
    ${SHIFT_REQUEST_FRAGMENT}
`;

export const CREATE_SHIFT_REQUEST_MUTATION = gql`
    mutation createShiftRequestMutation(
        $name: String!
        $description: [String!]
        $shiftCategoryId: String
        $contactId: String
        $requirements: [String!]
        $badgeRequirements: [String!]
        $employerId: String
        $assignedEmployerId: String
        $payCurrency: String
        $shifts: [ShiftArgument!]
        $shiftRequestCertificationsAttributes: [ShiftRequestCertificationArgument!]
        $shiftRequestSpecialtiesAttributes: [ShiftRequestSpecialtiesArgument!]
        $invitedWorkerIds: [String!]
        $state: String
        $facilityId: String
        $applyForJob: Boolean
        $useSmartRanking: Boolean
        $generalDetails: ShiftRequestGeneralDetailsArgument
    ) {
        createShiftRequest(
            name: $name
            description: $description
            shiftCategoryId: $shiftCategoryId
            contactId: $contactId
            requirements: $requirements
            badgeRequirements: $badgeRequirements
            employerId: $employerId
            assignedEmployerId: $assignedEmployerId
            payCurrency: $payCurrency
            shifts: $shifts
            shiftRequestCertificationsAttributes: $shiftRequestCertificationsAttributes
            shiftRequestSpecialtiesAttributes: $shiftRequestSpecialtiesAttributes
            invitedWorkerIds: $invitedWorkerIds
            state: $state
            facilityId: $facilityId
            applyForJob: $applyForJob
            useSmartRanking: $useSmartRanking
            generalDetails: $generalDetails
        ) {
            ...shiftRequestFragment
        }
    }
    ${SHIFT_REQUEST_FRAGMENT}
`;