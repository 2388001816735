import {
  store,
  computed,
  observable,
  action,
  event,
} from "~/common/mobx.decorator";
import { authStore, routerStore } from "~/stores";

import { EVENTS, PATHS } from "~/common/constants";
import { eventBus } from "mobx-event-bus2";
import { includes, isEmpty } from "lodash";
import {
  ADMIN_FEATURES,
  CLIENT_FEATURES,
  COMMON_FEATURES,
} from "~/common/constants/feature.constant";
import { GET_TOTAL_NOTIFICATIONS, apolloClient } from "~/common/apollo";
import { messagesStore } from "~/features/recruiter-messages/recruiter-messages.store";

const FEATURE_TYPE = {
  default: "default",
  common: "common",
};

@store()
class CareStore {
  @observable showProfileSidebar;
  @observable customHeader = {};
  @observable worker = {};
  @observable employerAppInit = null;

  getFeature = (type) => {
    const defaultMenu = authStore.isAdminLoggedIn
      ? ADMIN_FEATURES
      : CLIENT_FEATURES;
    const common = COMMON_FEATURES;

    let result = null;
    switch (type) {
      case FEATURE_TYPE.default:
        result = defaultMenu;
        break;
      case FEATURE_TYPE.common:
        result = common;
        break;
      default:
        result = [];
        break;
    }
    return result;
  };

  @computed
  get totalUnreadNotification() {
    return this.employerAppInit?.totalUnreadNotification || 0;
  }

  @computed
  get unreadMessageCounter() {
    return this.employerAppInit?.unreadMessageCounter || 0;
  }

  @computed
  get features() {
    const defaultMenu = this.getFeature(FEATURE_TYPE.default);

    return [...defaultMenu];
  }

  @computed
  get allFeatures() {
    const common = this.getFeature(FEATURE_TYPE.common);
    const allFeature = [...this.features, ...common];

    return allFeature?.find((feature) => feature.path === routerStore.basePath);
  }

  @computed
  get activeFeature() {
    return this.features?.find((feature) => {
      return isEmpty(feature?.subMenu)
        ? feature.path === routerStore.basePath
        : feature?.subMenu?.find(
            (subFeature) =>
              subFeature.path === routerStore.basePath ||
              subFeature.path === `${routerStore.basePath}/:id`
          );
    });
  }
  get activeSubFeature() {
    return this.activeFeature?.subMenu?.find(
      (feature) =>
        feature.path === routerStore.basePath ||
        feature.path === `${routerStore.basePath}/:id`
    );
  }

  @computed
  get activePage() {
    return this.activeSubFeature || this.activeFeature;
  }

  @computed
  get code() {
    return this.activeFeature?.code;
  }

  @computed
  get name() {
    return this.activeFeature?.name || "";
  }

  @computed
  get useLayout() {
    return this.activeFeature?.useLayout;
  }

  @computed
  get sidebarMenu() {
    return this.features?.filter((feature) => feature.showSideBar);
  }

  @computed
  get appbarMenu() {
    return this.features?.filter((feature) => feature.showAppBar);
  }

  @action
  toogleProfileSidebar = async () => {
    this.showProfileSidebar = !this.showProfileSidebar;
  };

  @action
  logout = async () => {
    this.showProfileSidebar = false;

    eventBus.post(EVENTS.authStore.logout);
    routerStore.goPage(PATHS.common.signin);
  };

  @action
  handleClickChangePassword = async () => {
    this.showProfileSidebar = false;
    await routerStore.goPage(PATHS.common.change_password);
  };

  @event(EVENTS.careStore.updateHeader)
  async onUpdateHeader({ payload }) {
    this.customHeader = payload;
  }

  @action
  fetchEmployeInit = async () => {
    const response = await apolloClient.query({
      query: GET_TOTAL_NOTIFICATIONS,
    });
    this.employerAppInit = response?.data?.employerAppInit;

    const currentPath = window.location.pathname;
    if (currentPath && includes(currentPath, "/message_conversations")) {
      await messagesStore.fetchMessageConversations();
      await messagesStore.fetchMessages();
    }
  };
}

export const careStore = new CareStore();
