import gql from "graphql-tag";
import { JOB_PLACEMENT_FRAGMENT } from "../apollo.fragment";

export const APPROVE_JOB_PLACEMENT_MUTATION = gql`
  mutation approveJobPlacementMutation($id: String!) {
    approveJobPlacement(id: $id) {
      ...jobPlacementFragment
    }
  }
  ${JOB_PLACEMENT_FRAGMENT}
`;

export const DENY_JOB_PLACEMENT_MUTATION = gql`
  mutation declineJobPlacementMutation(
    $id: String!
    $reasonMessage: String
    $notes: String
  ) {
    declineJobPlacement(id: $id, reasonMessage: $reasonMessage, notes: $notes) {
      ...jobPlacementFragment
    }
  }
  ${JOB_PLACEMENT_FRAGMENT}
`;

export const ACCEPT_JOB_PLACEMENT_MUTATION = gql`
  mutation acceptJobPlacementMutation($id: String!) {
    acceptJobPlacement(id: $id) {
      ...jobPlacementFragment
    }
  }
  ${JOB_PLACEMENT_FRAGMENT}
`;
