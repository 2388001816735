import { forEach, pick, set } from "lodash";

export const convertProfileToFormData = (currentPart) => {
  let workingPreferredLocations = currentPart?.integration?.workingPreferredLocations?.map(
    ({ id, preferredWorkingCity, preferredWorkingState }) => ({
      id,
      city: preferredWorkingCity,
      stateCode: preferredWorkingState,
      fullAddress: [preferredWorkingCity, preferredWorkingState, "US"].join(
        ", "
      ),
    })
  );

  let workingAuthorization = currentPart?.integration?.workingAuthorization;
  if (workingAuthorization === null) {
    workingAuthorization = {
      socialSecurityNumber: "",
      dateOfBirth: "",
    };
  }

  let workerAddress = currentPart?.integration?.workerAddress;
  if (workerAddress === null) {
    workerAddress = {
      street: "",
      city: "",
      state: "",
      country: "",
    };
  }
  workingPreferredLocations = Array(3)
    .fill()
    .map((_, index) => workingPreferredLocations[index] || undefined);

  return {
    ...currentPart?.integration,
    workingPreferredLocations,
    workingAuthorization,
    workerAddress,
    __workerOnboardingPart: currentPart,
  };
};

export const convertExperiencesToFormData = (currentPart) => {
  let workExperiences = currentPart?.integration?.workExperiences?.map(
    ({ city, state, ...exp }) => {
      return {
        ...exp,
        discipline: exp?.category?.id,
        location: !city && !state ? undefined : { city, stateCode: state },
      };
    }
  );

  if (!workExperiences || workExperiences.length === 0) {
    workExperiences = [{}];
  }

  return {
    ...currentPart.integration,
    workExperiences,
    __workerOnboardingPart: currentPart,
  };
};

export const convertLicensesToFormData = (currentPart) => {
  let workerLicenses =
    currentPart?.integration?.workerCertifications
      ?.filter((cert) => cert.certificationType === "license")
      .map((license) =>
        pick(license, [
          "id",
          "certificationType",
          "compactLicense",
          "expirationDate",
          "licenseDiscipline",
          "licenseNumber",
          "licenseState",
          "licenseImages",
        ])
      ) || [];
  let workerCertifications =
    currentPart?.integration?.workerCertifications
      ?.filter((cert) => cert.certificationType === "certification")
      .map((license) =>
        pick(license, [
          "id",
          "title",
          "licenseDiscipline",
          "certificationType",
          "expirationDate",
          "licenseNumber",
          "licenseImages",
        ])
      ) || [];

  if (workerLicenses.length === 0 && workerCertifications.length === 0) {
    workerLicenses = [{ certificationType: "license" }];
  }

  return {
    workerLicenses,
    workerCertifications,
    __workerOnboardingPart: currentPart,
  };
};

export const convertEducationToFormData = (currentPart) => {
  let workerEducations =
    currentPart?.integration?.workerEducations.map(
      ({ city, state, ...edu }) => ({
        ...edu,
        location: { city, stateCode: state },
      })
    ) || [];

  if (!workerEducations || workerEducations.length === 0) {
    workerEducations = [{}];
  }

  return {
    workerEducations,
    __workerOnboardingPart: currentPart,
  };
};

export const convertSpecialtyToFormData = (currentPart) => {
  let workerSpecialties = currentPart?.integration?.workerSpecialties || [];

  workerSpecialties = workerSpecialties.map((workerSpecialty) => {
    const skillChecklistQuestion = workerSpecialty?.workerSkillChecklist?.skillChecklist?.skillChecklistQuestions?.find(
      (ques) => ques.questionType.name === "Scale Matrix"
    );

    if (!skillChecklistQuestion) {
      return workerSpecialty;
    }

    const workerSkillChecklistAnswers = [
      {
        workerId: workerSpecialty.workerSkillChecklist.worker?.id,
        skillChecklistQuestionId: skillChecklistQuestion.id,
        answers: {
          question_id: skillChecklistQuestion.externalId,
          answer: skillChecklistQuestion.question.rows?.map((row) => {
            return {
              row_category_id: row.row_category_id,
              row_category_options: row.row_category_options?.map((option) => ({
                row_category_option_id: option.id,
                choices: skillChecklistQuestion?.question.choice_category?.map(
                  (choice) => ({ choice_id: choice.id, answer: "" })
                ),
              })),
            };
          }),
        },
      },
    ];

    forEach(
      workerSpecialty?.workerSkillChecklist?.workerSkillChecklistAnswers,
      (checklist, checklistIndex) => {
        forEach(checklist?.answers?.answer, (answer) => {
          forEach(answer?.row_category_options, (option) => {
            forEach(option?.choices, (choice) => {
              set(
                workerSkillChecklistAnswers,
                `${checklistIndex}.answers.answer.${answer?.row_category_id}.row_category_options.${option?.row_category_option_id}.choices.${choice?.choice_id}.answer`,
                choice.answer
              );

              set(
                workerSkillChecklistAnswers,
                `${checklistIndex}.id`,
                checklist.id
              );
            });
          });
        });
      }
    );

    return {
      ...workerSpecialty,
      workerSkillChecklist: {
        ...workerSpecialty.workerSkillChecklist,
        workerSkillChecklistAnswers,
      },
    };
  });

  return {
    workerSpecialties,
    __workerOnboardingPart: currentPart,
  };
};

export const convertTermToFormData = (currentPart) => {
  const workerQuizAnswers =
    currentPart?.integrationConfigurations?.quiz?.quizQuestions?.map(
      (question) => {
        const quizAnswer = question?.quizAnswers?.find(
          (answer) => !!answer.workerAnswer
        );

        return {
          quizAnswerId: quizAnswer?.id,
          reason: quizAnswer?.workerAnswer?.reason,
        };
      }
    ) || [];

  return {
    workerQuizAnswers: workerQuizAnswers,
    termsOfServiceId: !!currentPart?.integration?.termsOfService?.id,
    __workerOnboardingPart: currentPart,
  };
};
