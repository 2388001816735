import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query getNotifications(
    $after: String
    $first: Int
    $before: String
    $last: Int
  ) {
    notifications(after: $after, first: $first, before: $before, last: $last) {
      nodes {
        companyId
        createdAt
        event
        formattedMessage
        id
        isRead
        message
        objectId
        objectType
        sender
        status
        url
        userId
        userType
        heading
        options
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      totalCount
      totalRead
      totalUnread
    }
  }
`;

export const GET_TOTAL_NOTIFICATIONS = gql`
  query {
    employerAppInit {
      totalUnreadNotification
      unreadMessageCounter
    }
  }
`;
