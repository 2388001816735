export const USER_TITLE = {
  manager: "Manager",
  admin: "Admin",
  onsite_manager: "Onsite Manager",
};

export const USER_TYPE = {
  employer: "Employer",
  worker: "Worker",
};
